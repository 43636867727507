import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Send, Mail } from 'react-feather';
import { contactSupport } from '../../api';
import JumbleModal from '../../components/jumbleModal/JumbleModal';
import { useGeneralContext } from '../../context';
import { ReactComponent as WhatsappIcon } from '../../resources/WhatsApp.svg';

import '../../css/App.css';
import '../../css/Font.css';
import './feedbackModal.css';

const FeedbackModal = () => {
  const context = useGeneralContext();
  const { feedbackModalOpen, setFeedbackModalOpen } = context;
  return (
    <JumbleModal
      showModal={feedbackModalOpen}
      closeModal={() => setFeedbackModalOpen(false)}
      modalSections={[() => <ContactModalContent />, () => <FeedbackModalContent />]}
    />
  );
};

const ContactModalContent = () => {
  // Get the current time in GMT+1
  const currentTime = new Date().toLocaleString('en-US', { timeZone: 'Europe/Lisbon' });

  // Convert the currentTime string to a Date object
  const currentDate = new Date(currentTime);

  // Check if it's a weekday (Monday to Friday)
  const isWeekday = currentDate.getDay() >= 1 && currentDate.getDay() <= 5;

  // Check if it's between 9 AM and 5 PM
  const isWorkingHours = currentDate.getHours() >= 9 && currentDate.getHours() < 17;

  const amOnline = isWeekday && isWorkingHours;

  return (
    <div className="feedback-form-container">
      <div className="flex-column-centered flex-gap-4">
        <div className="text text--heading-4 text--bold text--neutral-800">Do you have feedback?</div>
        <div className="text text--paragraph-1 text--neutral-600">Let us know for a free month of Premium</div>
        <div style={{ width: '100%' }}>
          <div className="flex-row-start flex-gap-16 margin-top-16">
            <div style={{ width: '50px' }}>
              <div style={{ position: 'relative', width: '50px', height: '50px' }}>
                <img
                  className="user-icon"
                  src={require('../../resources/tony-icon-5.jpg')}
                  alt="User support icon"
                ></img>
                <span
                  className={`user-icon-status ${amOnline ? 'user-icon-status--online' : 'user-icon-status--offline'}`}
                ></span>
              </div>
            </div>
            <div className="flex-column-left flex-gap-8" style={{ height: '50px' }}>
              <div className="text text--paragraph-1 text--neutral-700">support@jumblejournal.org</div>
              <div className="text text--paragraph-1 text--success-600">will reply in 24 hrs</div>
            </div>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <div className="flex-row-start flex-gap-16 margin-top-16">
            <div style={{ width: '50px' }}>
              <a href="https://wa.me/351913613574" target="_blank" rel="noopener noreferrer">
                <WhatsappIcon style={{ width: '50px', height: '50px' }} />
              </a>
            </div>
            <div className="flex-column-left flex-gap-8" style={{ height: '50px' }}>
              <div className="text text--paragraph-1 text--neutral-700">+351 913 613 574</div>
              <div className={`text text--paragraph-1 ${'text--success-600'}`}>9-5 mon-fri (Europe/Lisbon)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeedbackModalContent = () => {
  const context = useGeneralContext();
  const { setFeedbackModalOpen } = context;
  const [sending, setSending] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    try {
      setSending(true);
      // Prevent page reload
      event.preventDefault();

      const formData = new FormData(event.target);
      const formDataObj = Object.fromEntries(formData.entries());
      const email = formDataObj.email as string;
      const subject = formDataObj.subject as string;
      const body = formDataObj.message as string;

      const result = await contactSupport({ email, subject, body });
      if (!result) {
        setSending(false);
        setSent(false);
        return;
      }
      setSending(false);
      setSent(true);
    } catch (e) {
      setSending(false);
      window.alert('Something went wrong. Please try again.');
    }
  };

  return (
    <div className="feedback-form-container">
      <Form id="contact-form" className="" onSubmit={handleSubmit} style={{ width: '100%' }}>
        <div className="flex-column-left flex-gap-32">
          <div className="flex-column-left flex-gap-20">
            <Mail size={32} />
            {sent ? <SuccessText /> : <FeedbackText />}
          </div>
          <div className="feedback-user-entry-container">
            <Form.Group style={{ width: '100%' }} controlId="contact-us-email">
              <Form.Label className="text text--semi-bold text--paragraph-1">Your Email</Form.Label>
              <Form.Control
                required
                disabled={sent}
                aria-label="contact form email"
                type="email"
                name="email"
                placeholder="email@email.com"
                size="lg"
                autoComplete="username"
                style={{ width: '100%' }}
              />
            </Form.Group>
            <Form.Group style={{ width: '100%' }} controlId="sign-in-password">
              <Form.Label className="text text--semi-bold text--paragraph-1">Subject</Form.Label>
              <Form.Control
                disabled={sent}
                required
                aria-label="contact subject"
                type="text"
                name="subject"
                size="lg"
              />
            </Form.Group>
            <Form.Group style={{ width: '100%' }} controlId="sign-in-password">
              <Form.Label className="text text--semi-bold text--paragraph-1">Message</Form.Label>
              <Form.Control
                disabled={sent}
                required
                aria-label="contact message"
                as="textarea"
                rows={6}
                type="textarea"
                name="message"
                size="lg"
              />
            </Form.Group>
          </div>
          <div className="flex-row-centered flex-gap-12" style={{ width: '100%' }}>
            <button
              style={{ width: '50%' }}
              className="jumble-button-secondary text text--paragraph-2 text--semi-bold text--primary-700"
              onClick={() => {
                setFeedbackModalOpen(false);
              }}
            >
              {sent ? 'Close' : 'Cancel'}
            </button>
            <button
              disabled={sent}
              style={{ width: '50%' }}
              className="jumble-button-primary text text--semi-bold text--paragraph-2 text--white"
            >
              {sending ? (
                <span
                  style={{ marginLeft: '4px', marginRight: '4px', height: '16px' }}
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : sent ? (
                <span role="img" aria-label="success">
                  ✅
                </span>
              ) : (
                <Send style={{ height: '16px' }} />
              )}
              {sent ? 'Message Sent!' : 'Send now'}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

const FeedbackText = () => {
  return (
    <div className="flex-column-left flex-gap-12">
      <div className="text text--subheading text--semi-bold">Chat with Us</div>
      <div className="text text--paragraph-2">Have an idea? I'm all ears!</div>
      <div className="text text--paragraph-2">
        See a bug?{' '}
        <span role="img" aria-label="italian-hand">
          🤌
        </span>{' '}
        I'm sorry about that! I'm human, let me know and I'll fix it.
      </div>
    </div>
  );
};

const SuccessText = () => {
  return (
    <div className="flex-column-left flex-gap-12">
      <div className="text text--subheading text--semi-bold">Message Sent Successfully! ✅</div>
      <div className="text text--paragraph-2">
        Thank you for reaching out!{' '}
        <span role="img" aria-label="italian-hand">
          🙏
        </span>{' '}
      </div>
      <div className="text text--paragraph-2">I'll get back to you as soon as possible.</div>
    </div>
  );
};

export default FeedbackModal;
