import React, { useState, KeyboardEvent } from 'react';
import '../../css/App.css';
import { TagListParams } from './types';
import { Trash2 } from 'react-feather';
import { addTag, deleteTag } from '../../api';
import './tags.css';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useGeneralContext } from '../../context';

interface NewTagInputParams {
  creationDate: string;
  entryTags: string[];
  darkMode: boolean;
  blur: boolean;
  setTags: (tags: string[]) => void;
}

const NewTagInput = (params: NewTagInputParams) => {
  const { creationDate, entryTags, darkMode, blur, setTags } = params;
  const [focus, setFocus] = useState(false);
  const [newTagInput, setNewTagInput] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const context = useGeneralContext();
  const { allTags } = context;

  const validTags = allTags.filter((tag) => tag !== null && !entryTags.includes(tag));

  const saveNewTag = async (value: string, event: KeyboardEvent) => {
    // TODO seperate saving of tags from journal entries ?
    setNewTagInput('');
    if (!value || entryTags.indexOf(value) !== -1) {
      return;
    }

    try {
      await addTag({
        creationDate,
        value
      });
      setTags([...entryTags, value]);
    } catch (error: any) {
      if (error instanceof AxiosError && error?.response?.status === 404) {
        window.alert('Unable to save because you have been logged out. Please log back in.');
        history.push('/');
      }
    }
    (event.target as HTMLInputElement).value = '';
  };

  // a hack to catch clicks in the list after onchange updates the state
  const handleKeyUp = async (event: KeyboardEvent) => {
    const listClicked = event.code === undefined;
    if (listClicked) {
      setFocus(false);
      setLoading(true);
      await saveNewTag(newTagInput, event);
      setLoading(false);
    }
  };

  const handleKeyDown = async (event: any) => {
    if (event.key !== 'Enter') {
      return;
    }

    const value: string = event.target.value && event.target.value.trim();
    event.target.value = '';
    setFocus(false);
    setLoading(true);
    await saveNewTag(value, event);
    setLoading(false);
  };

  const onFocus = () => {
    setFocus(true);
    setNewTagInput('');
  };

  const onBlur = () => {
    setFocus(false);
    setNewTagInput('');
  };

  const onChange = (event: any) => {
    setNewTagInput(event.target.value);
  };

  return loading ? (
    <div className="single-entry__tag-spinner-container">
      <span style={{ height: '1vw', width: '1vw' }} className="spinner-border" role="status" aria-hidden="true"></span>
    </div>
  ) : (
    <li key="new-tag" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <input
        // tells mobile to display a 'go' button which acts like 'enter' on a keyboard
        inputMode="search"
        key="input"
        value={newTagInput}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        list="tags"
        type="text"
        placeholder={focus ? '' : '+ add tag'}
        className={`
        ${blur && 'blur'}
        ${darkMode ? 'text--neutral-50' : 'text--primary-900'}
        ${focus ? 'single-entry__tag' : 'tag__new-tag-without-focus'} text text--paragraph-2 text--semi-bold`}
      />
      <datalist id="tags">
        {validTags.map((tag, i) => (
          <option key={`${tag}-${i}`}>{tag}</option>
        ))}
      </datalist>
    </li>
  );
};

export const TagList = (params: TagListParams) => {
  const { creationDate, entryTagStrings, darkMode, blur, setTags } = params;
  const [indexesLoading, setLoadingIndexes] = useState<number[]>([]);

  const [mousedOverTagIndex, setMousedOverTagIndex] = useState<number | null>(null);

  return (
    <ul className="calendar__item-tags">
      <NewTagInput
        darkMode={darkMode}
        creationDate={creationDate}
        entryTags={entryTagStrings}
        blur={blur}
        setTags={setTags}
      />
      {entryTagStrings.map((tag, index) => (
        <li key={index.toString()}>
          {indexesLoading.includes(index) ? (
            <div className="single-entry__tag-spinner-container">
              <span
                style={{ color: 'red', height: '1vw', width: '1vw' }}
                className="spinner-border"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          ) : (
            <button
              onMouseEnter={() => setMousedOverTagIndex(index)}
              onMouseLeave={() => setMousedOverTagIndex(null)}
              title={tag}
              className={`${
                blur && 'blur'
              } single-entry__tag single-entry__tag-deletion-highlight text text--body-medium text--semi-bold ${
                darkMode ? 'text--neutral-100' : 'text--primary-900'
              }`}
              onClick={async () => {
                if (window.confirm(`Delete "${tag}" tag?`)) {
                  setLoadingIndexes([...indexesLoading, index]);

                  await deleteTag({
                    value: tag,
                    creationDate
                  });
                  setTags(entryTagStrings.filter((t) => t !== tag));
                  setLoadingIndexes([
                    ...indexesLoading.slice(0, indexesLoading.indexOf(index)),
                    ...indexesLoading.slice(indexesLoading.indexOf(index) + 1, indexesLoading.length)
                  ]);
                }
              }}
            >
              {mousedOverTagIndex === index ? (
                <div>
                  <Trash2 size={18} /> {trim(tag)}
                </div>
              ) : (
                trim(tag)
              )}
            </button>
          )}
        </li>
      ))}
    </ul>
  );
};

const trim = (tag: string) => {
  if (!tag) {
    return tag;
  }
  return tag.length > 12 ? tag.slice(0, 9) + '...' : tag;
};
