import React, { useState, useRef, useEffect } from 'react';
import { useGeneralContext } from '../../context';
import { useLocation } from 'react-router-dom';
import { Plus } from 'react-feather';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import simpleLogo from '../../resources/logo-simple.png';
import JumbleModal from '../../components/jumbleModal/JumbleModal';
import { SupportedCurrencyCode, isSupportedCurrencyCode, premiumFeatures } from './data';
import { createCheckoutSession } from '../../api/stripe-api';
import { SubscriptionPlan, isSubscriptionPlan } from '../settings/types';
import config from '../../config';
import '../../css/App.css';
import '../../css/Font.css';
import './choosePlan.css';

const ChoosePlan = () => {
  const context = useGeneralContext();
  const { nickname, plansModalOpen: showPlans, setPlansModalOpen } = context;
  const closeModal = () => setPlansModalOpen(false);
  const isLandingPage = useLocation().pathname === '/';
  return (
    <JumbleModal
      showModal={showPlans}
      closeModal={closeModal}
      modalSections={[
        () => <PlanSelectionSection showPlans={showPlans} closeModal={closeModal} isLandingPage={isLandingPage} />,
        () => <EncouragementMessage nickname={nickname} isLandingPage={isLandingPage} />
      ]}
    />
  );
};

const PlanSelectionSection = (props: { showPlans: boolean; closeModal: () => void; isLandingPage: boolean }) => {
  const context = useGeneralContext();
  const { currency } = context;
  const { closeModal, isLandingPage } = props;

  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(false);

  const modalRef = useRef<HTMLDivElement>(null);

  const queryParams = new URLSearchParams(location.search);
  const plan = queryParams.get('plan');
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan | 'lifetime' | null>(
    (plan && isSubscriptionPlan(plan)) || plan === 'lifetime' ? plan : null
  );

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeModal]);

  const plans: { name: string; id: SubscriptionPlan; price: string }[] = [
    {
      name: 'Monthly',
      id: 'premium_monthly',
      price: `${currency.symbol}${currency.code === 'aud' ? '10' : '5'} / month`
    },
    {
      name: 'Annually',
      id: 'premium_yearly',
      price: `${currency.symbol}${currency.code === 'aud' ? '100' : '50'} / year`
    }
  ];

  const lifetimeDeal = {
    name: 'Lifetime Access',
    id: 'lifetime',
    price: `Single payment of ${currency.symbol}${currency.code === 'aud' ? '350' : '175'}`
  };

  const selectedPlanPrice =
    selectedPlan === 'lifetime' ? lifetimeDeal.price : plans.find((plan) => plan.id === selectedPlan)?.price;

  const title = isLandingPage ? 'Premium Plans' : 'Choose your plan';
  const closeButtonText = isLandingPage ? 'Close' : 'Cancel';
  return (
    <div className="flex-column-centered flex-gap-8">
      <div className="plan-form__header-container">
        <img alt="logo" className="plan-form__logo" src={simpleLogo}></img>
        <div className="text text--bold">{title}</div>
      </div>
      <div
        className={`plan-form__button padding-sides-16 ${
          selectedPlan && isSubscriptionPlan(selectedPlan) ? 'selected-plan' : ''
        }`}
      >
        <div className="flex-item-aligned-center text text--bold text--paragraph-2">{`${config.freeTrialPeriodInDays}-day free trial`}</div>
        <div className="flex-item-aligned-center text text--paragraph-2">FREE</div>
      </div>
      <Plus className="plus-icon" />
      {plans.map((plan, index) => {
        return (
          <button
            key={`plan-${index}`}
            className={`plan-form__button padding-sides-16 ${selectedPlan === plan.id ? 'selected-plan' : ''}`}
            onClick={() => {
              setSelectedPlan(plan.id);
            }}
            disabled={loading}
          >
            <div className="flex-item-aligned-center text text--bold text--paragraph-2">{plan.name}</div>
            <div className="flex-item-aligned-center text text--paragraph-2">{plan.price}</div>
          </button>
        );
      })}
      OR
      <button
        className={`plan-form__button padding-sides-16 ${selectedPlan === 'lifetime' ? 'selected-plan' : ''}`}
        onClick={() => {
          setSelectedPlan('lifetime');
        }}
        disabled={loading}
      >
        <div className="flex-item-aligned-center text text--bold text--paragraph-2">{lifetimeDeal.name}</div>
        <div className="flex-item-aligned-center text text--paragraph-2">{lifetimeDeal.price}</div>
      </button>
      <PremiumFeatures />
      <div className="plan-form__footer-container">
        <button
          disabled={loading}
          onClick={() => {
            closeModal();
          }}
          className="plan-form__cancel-button text text--paragraph-2 text--semi-bold text--primary-700"
        >
          {closeButtonText}
        </button>
        {isLandingPage || (
          <CheckoutButton selectedPlan={selectedPlan} loading={loading} setLoading={setLoading} currency={currency} />
        )}
      </div>
      {selectedPlanPrice && selectedPlan === 'lifetime' ? (
        <div className="subscription-caption-container">
          <div className="text text--caption text--neutral-600">{`${
            selectedPlanPrice && selectedPlanPrice.replace('/', 'per')
          }`}</div>
          <div className="text text--paragraph-1 text--bold text--neutral-800">1 month refund period</div>
          <div className="text text--caption text--neutral-600">No questions asked</div>
        </div>
      ) : (
        selectedPlanPrice && (
          <div className="subscription-caption-container">
            <div className="text text--caption text--neutral-600">Try Jumble Premium</div>
            <div className="text text--paragraph-1 text--bold text--neutral-800">{`${config.freeTrialPeriodInDays} days free`}</div>
            <div className="text text--caption text--neutral-600">{`Then ${
              selectedPlanPrice && selectedPlanPrice.replace('/', 'per')
            }`}</div>
          </div>
        )
      )}
    </div>
  );
};

const CheckoutButton = (props: {
  selectedPlan: SubscriptionPlan | 'lifetime' | null;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  currency: {
    code: SupportedCurrencyCode;
    symbol: string;
  };
}) => {
  const { selectedPlan, loading, setLoading, currency } = props;
  return (
    <div className="plan-form__checkout-form">
      <button
        disabled={selectedPlan === null || loading}
        className="plan-form__checkout-button text text--paragraph-2 text--semi-bold text--white"
        type="button"
        onClick={async () => {
          setLoading(true);
          if (selectedPlan === null) {
            return;
          }
          const currencyCode = currency.code;
          try {
            const response = await createCheckoutSession({
              planId: selectedPlan,
              isUpgrade: false,
              currency: isSupportedCurrencyCode(currencyCode) ? currencyCode : 'usd'
            });
            const redirectLocation = response?.data?.location;
            if (!redirectLocation) {
              throw new Error('failed to create strip customer portal');
            }
            window.location.assign(redirectLocation);
          } catch (error) {
            setLoading(false);
            window.alert(
              'There was a problem creating stripe checkout session. Please try again. If the issue persists, let us know at support@jumblejournal.org'
            );
            console.log(error);
          }
        }}
      >
        {loading && (
          <span
            style={{ marginRight: '1em', textAlign: 'center', height: '1em', width: '1em' }}
            className="spinner-border"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        Checkout
      </button>
    </div>
  );
};

const PremiumFeatures = () => {
  return (
    <div className="flex-row-start flex-wrap flex-gap-16">
      <div className="premium-features-grid">
        {premiumFeatures.map((feature, index) => {
          return (
            <OverlayTrigger
              key={`premiumfeature-${index}`}
              placement="top"
              overlay={<Tooltip id={feature.name}>{feature.description}</Tooltip>}
            >
              <div className="premium-feature-pill">
                <div className="text text--paragraph-2">{feature.icon}</div>
                <div className="text text--caption">{feature.name}</div>
              </div>
            </OverlayTrigger>
          );
        })}
      </div>
    </div>
  );
};

const EncouragementMessage = (props: { nickname: string; isLandingPage: boolean }) => {
  const { nickname } = props;
  const messages = [
    `Hello ${nickname || '👋'},`,
    'Thank you for considering Jumble.',
    'This membership is an investment in yourself. Journaling improves clarity, communication, and overall mental health.',
    ' Our team works every day to make Jumble the best journaling experience in the world. We hope you enjoy.',
    'Your support means the world to us.'
  ];
  return (
    <div className="text text--paragraph-2 text--neutral-600 flex-column-left flex-gap-16">
      {messages.map((msg, index) => {
        return (
          <div key={`encouragement-${index}`} className="encouragement-msg-container">
            {msg}
          </div>
        );
      })}
      <div>
        <div className="text--bold encouragement-msg-container">Tony Oreglia | Founder of Jumble</div>
      </div>
    </div>
  );
};

export default ChoosePlan;
