import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './css/index.css';
import './css/App.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <App className="app" />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);
