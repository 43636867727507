import { Item } from './types';
export const items: Item[] = [
  {
    title: 'Thalita Milan',
    role: 'Independent HubSpot consultant, Marketing Automation expert and Bestselling Udemy Instructor',
    location: 'Portugal',
    data: 'Jumble has drastically improved my morning routine ☕🤩. I love being able to chat with past journal entries ... it adds a unique dimension to my introspective journey.',
    date: 'January 26, 2023',
    icon: <img className="user-icon" src={require('../../resources/thali.jpeg')} alt="Thalita Milan"></img>
  },
  {
    title: 'Ose',
    role: 'Software Engineer',
    location: 'United Kingdom',
    data: 'Thank you for producing this app. I was working on something similar myself. Finding this has saved me so much time.',
    date: 'April 11, 2024',
    icon: <img className="user-icon" src={require('../../resources/viking.png')} alt="Ose the viking"></img>
  },
  {
    title: 'Kelley Bontemps',
    role: 'Equity Capital Markets Manager and Professional Artist',
    location: 'New York',
    data: 'I love how the AI provides unique prompts and writing suggestions. It really helps me get started journaling and explore my thoughts and emotions on a deeper level.',
    date: 'March 3, 2023',
    icon: <img className="user-icon" src={require('../../resources/KB.jpeg')} alt="Kelley Bontemps"></img>
  },
  {
    title: 'Adrien',
    role: 'Senior Backend Software Engineer',
    location: 'France',
    data: '⭐⭐ I really like the look and feel, "vibe", simplicity and minimalism',
    date: 'June 18, 2022',
    icon: <img className="user-icon" src={require('../../resources/adrien.jpeg')} alt="Adrien"></img>
  },
  {
    title: 'Lívia Câmara',
    role: 'UX Designer',
    data: 'As a journaling enthusiast I loved how straight-forward it is, how easy it is to start using and the aesthetics of it is pleasant and noise-free',
    date: 'February 10, 2023',
    icon: <img className="user-icon" src={require('../../resources/livia.jpeg')} alt="Livia"></img>
  },
  {
    title: 'We would love to hear your story!',
    role: 'Receive free premium for sharing your story with us.',
    data: '',
    date: '',
    icon: (
      <img
        className="carousel-cta-icon"
        alt="journal lines coming off page"
        src={require('../../resources/KB_jumble-lines-alive.png')}
      ></img>
    ),
    cta: true,
    centeredContent: true
  }
];
