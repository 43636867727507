import { useState } from 'react';
import toast from 'react-hot-toast';
import { createUserImportationEmail } from '../../api/import-email-api';
import PremiumUpsellModal from '../../components/PremiumUpsellModal';
import { useGeneralContext } from '../../context';

const PersonalJumbleEmail = () => {
  const context = useGeneralContext();
  const { importationEmail } = context;
  const [copied, setCopied] = useState(false);

  return (
    <div>
      {importationEmail ? (
        <DisplayEmail copied={copied} setCopied={setCopied} importationEmail={importationEmail} />
      ) : (
        <GenerateEmailButton />
      )}
    </div>
  );
};

const GenerateEmailButton = () => {
  const context = useGeneralContext();
  const { subscriptionStatus, setImportationEmail } = context;
  const [showModal, setShowModal] = useState(false);
  const [generating, setGenerating] = useState(false);
  return (
    <>
      <PremiumUpsellModal
        showModal={showModal}
        setShowModal={setShowModal}
        message="Get access to Jumble's integration with reMarkable paper-like writing tablet."
      />

      <button
        className="jumble-button-secondary text text--paragraph-1 text--semi-bold text--primary-700"
        type="submit"
        onClick={async () => {
          try {
            if (!subscriptionStatus || subscriptionStatus === 'free') {
              setShowModal(true);
              return;
            }
            setGenerating(true);
            const result = await createUserImportationEmail();
            const importationEmail = result.data.importationEmail;
            setGenerating(false);
            setImportationEmail(importationEmail);
          } catch (error) {
            console.log('error::::', error);
            window.alert(
              'There was an error generating your personal Jumble email address. Please try again. If the issue persists, let us know at support@jumblejournal.org'
            );
            setGenerating(false);
          }
        }}
      >
        {generating && (
          <span
            style={{ marginRight: '1em', textAlign: 'center', height: '1em', width: '1em' }}
            className="spinner-border"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        Generate Email Address
      </button>
    </>
  );
};

const DisplayEmail = (props: {
  copied: boolean;
  setCopied: React.Dispatch<React.SetStateAction<boolean>>;
  importationEmail: string;
}) => {
  const { copied, setCopied, importationEmail } = props;

  return (
    <div className="text text--semi-bold text--paragraph-2 text--neutral-900 flex-column-left flex-gap-6">
      {importationEmail}
      <button
        className={`copy-clipboard-button text text--caption ${
          copied ? 'copy-clipboard-button--default' : 'copy-clipboard-button--copied'
        }`}
        type="submit"
        onClick={() => {
          importationEmail && navigator.clipboard.writeText(importationEmail);
          toast.success('Copied to clipboard!');
          setCopied(true);
        }}
      >
        {copied ? 'Copied Successfully' : 'Copy Email to Clipboard'}
      </button>
    </div>
  );
};

export default PersonalJumbleEmail;
