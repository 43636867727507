import React from 'react';
import simpleLogo from './resources/dark-logo.png';

const components = {
  Header() {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <img style={{ width: '80%', height: 'auto', marginBottom: '1.5em' }} alt="Amplify logo" src={simpleLogo} />
      </div>
    );
  },
  Footer() {
    return (
      <div className="text text--caption" style={{ textAlign: 'left', padding: '1em' }}>
        <p>&copy; All Rights Reserved</p>
      </div>
    );
  }
};

export default components;
