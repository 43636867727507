import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../resources/dark-logo.png';
import simpleLogo from '../resources/logo-simple.png';
import '../css/Logo.css';

const Logo = (props: { style: any; includeTitle: boolean }) => {
  const { style, includeTitle } = props;
  return (
    <Link style={style} to="/home">
      <img style={style} alt="logo" className="logo-img" src={includeTitle ? logo : simpleLogo}></img>
    </Link>
  );
};

export default Logo;
