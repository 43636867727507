const isProd = process.env.NODE_ENV === 'production';
const config = {
  journalAppUri: isProd
    ? 'https://jumblejournal.org/prod'
    : process.env.NODE_ENV === 'test'
    ? undefined
    : 'http://localhost:3001',

  // for development, to avoid abusing lambda fxn during development
  journalEntries: isProd
    ? []
    : [
        { Key: 'tonyoreglia/20220429010101000' },
        { Key: 'tonyoreglia/20220430010101000' },
        { Key: 'tonyoreglia/20220501182932912' },
        { Key: 'tonyoreglia/20220501183256866' },
        { Key: 'tonyoreglia/20220501183352393' },
        { Key: 'tonyoreglia/20220502205101650' },
        { Key: 'tonyoreglia/20220505104301809' },
        { Key: 'tonyoreglia/20220514145956193' }
      ],

  accounts: isProd ? [] : [{ email: 'tony.oreglia@gmail.com', key: '' }],
  freeTrialPeriodInDays: 14
};

export default config;
