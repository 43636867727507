import React, { useState, useRef, useEffect } from 'react';
import { Form, Overlay, Spinner, Popover } from 'react-bootstrap';
import CreateNewEntryButton from './CreateNewEntryButton';
import simpleLogo from '../../resources/logo-simple.png';
import './gptPromptCard.css';
import { EntryBlockWithContext } from '../../components/calendar/types';
import { getDateStringFromCreationDate } from '../../util/dateTime';
import { loadExistingEntry } from '../../components/calendar/calendar';
import { useHistory } from 'react-router-dom';

const AssistantPill = (props: { value: string; context: string[]; conversation: EntryBlockWithContext[] }) => {
  const { value, context, conversation } = props;
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [loading, setLoading] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };
  const history = useHistory();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return context.length > 0 ? (
    <div ref={ref}>
      <button onClick={handleClick} className="assistant-pill text text--paragraph-1">
        {value}
      </button>
      <Overlay show={show} target={target} placement="right" container={ref} containerPadding={20}>
        <Popover id="popover-contained">
          <Popover.Header as="h3">Entries used in this response</Popover.Header>
          <Popover.Body>
            {loading ? (
              <Spinner animation="border" />
            ) : (
              [...new Set(context.map((i) => i.split('-')[0]))]
                .sort((a, b) => Number(a) - Number(b))
                .map((entry, i) => {
                  return (
                    <div key={i}>
                      <button
                        disabled={loading}
                        className="text text--paragraph-1 chat-context-link"
                        onClick={async () => {
                          try {
                            setLoading(true);
                            sessionStorage.setItem('conversation', window.btoa(JSON.stringify(conversation)));
                            const le = await loadExistingEntry({
                              entryName: entry,
                              setOpeningEntry: () => {}
                            });
                            const entryState = [
                              {
                                ...le,
                                name: entry
                              }
                            ];
                            setLoading(false);
                            return history.push({
                              pathname: `/entry/${entry}`,
                              state: {
                                entryState
                              }
                            });
                          } catch (error) {
                            setLoading(false);
                            console.log(error);
                            window.alert(
                              'Error loading entry. Please try again. If this issue persists, please contact us at support@jumblejournal.org'
                            );
                          }
                        }}
                      >
                        {getDateStringFromCreationDate(entry)}
                      </button>
                    </div>
                  );
                })
            )}
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  ) : (
    <div className="assistant-pill text text--paragraph-1">{value}</div>
  );
};

const UserPill = (props: { value: string; key: number }) => {
  const { value, key } = props;
  return (
    <div key={key} className="user-pill text text--paragraph-1 text--bold">
      {value}
    </div>
  );
};

const GptPromptCard = (props: {
  conversation: EntryBlockWithContext[];
  handleUserQuery: (conversation: EntryBlockWithContext[]) => Promise<void>;
  clearConversation: () => void;
  isLoadingEntries?: boolean;
  navigateToDailyEntry?: () => void;
  customClass?: string;
  placeholder?: string;
}) => {
  const {
    navigateToDailyEntry,
    isLoadingEntries,
    conversation,
    clearConversation,
    handleUserQuery,
    customClass,
    placeholder
  } = props;

  const [userQuery, setUserQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const convoBoxRef = useRef<HTMLDivElement | null>(null);

  // scroll to top
  useEffect(() => {
    if (convoBoxRef.current) {
      const div = convoBoxRef.current;
      smoothScrollTo(div, div.scrollHeight - div.clientHeight, 500); // 500ms animation
    }
  }, []);

  const handleNewPrompt = async () => {
    if (!userQuery) {
      window.alert('The prompt cannot be empty 🫠⁭');
      return;
    }

    try {
      setLoading(true);
      const newConversation = [
        ...conversation,
        { role: 'user', content: userQuery, context: [] } as EntryBlockWithContext
      ];
      await handleUserQuery(newConversation);
      setUserQuery('');
    } catch (error) {
      window.alert('Error connecting to AI. Please try again. Sometimes the servers are slow 🐢');
    }
    setLoading(false);
  };

  return (
    <div className={`home__prompt-container ${customClass || ''}`}>
      <div className="gpt-prompt-card__fixed-button-container">
        <button
          disabled={loading}
          className={`clear-conversation-button text text--caption`}
          onClick={clearConversation}
        >
          clear
        </button>
        {navigateToDailyEntry && (
          <CreateNewEntryButton
            btnText="Journal from prompt"
            className={`create-entry-button text text--caption text--bold ${conversation.length <= 3 ? 'opaque' : ''}`}
            navigateToDailyEntry={navigateToDailyEntry}
            disabled={loading || isLoadingEntries || conversation.length <= 3}
          />
        )}
      </div>

      <div className="conversation-container" ref={convoBoxRef}>
        {conversation.map((item, i) => {
          return item.role === 'assistant' ? (
            <AssistantPill value={item.content} key={i} context={item.context} conversation={conversation} />
          ) : (
            <UserPill value={item.content} key={i} />
          );
        })}
        {loading && (
          <div className="flex-row-start flex-gap-4">
            <img
              style={{ opacity: '0.6', height: '24px', width: '24px' }}
              alt="logo"
              className="logo-img blinking-element"
              src={simpleLogo}
            ></img>
            <div className="text text--paragraph-3 text--primary-300">Thinking...</div>
          </div>
        )}
      </div>
      <div className="user-conversation-entry-container">
        <Form.Control
          onChange={(event) => {
            setUserQuery(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleNewPrompt();
            }
          }}
          className="user-conversation-entry"
          placeholder={placeholder || 'Write here...'}
          value={userQuery}
        />
        <button
          className="generate-prompt-button text text--paragraph-1 text--bold"
          disabled={loading}
          onClick={handleNewPrompt}
        >
          Generate
        </button>
      </div>
    </div>
  );
};

function smoothScrollTo(element: HTMLElement, target: number, duration: number) {
  const start = element.scrollTop;
  const change = target - start;
  let startTime: number | null = null;

  function animateScroll(currentTime: number) {
    if (startTime === null) startTime = currentTime;
    const elapsed = currentTime - startTime;

    const progress = elapsed / duration;

    if (progress < 1) {
      element.scrollTop = start + change * progress;
      window.requestAnimationFrame(animateScroll);
    } else {
      element.scrollTop = target;
    }
  }

  window.requestAnimationFrame(animateScroll);
}

const ChatWithJournalCard = (props: {
  conversation: EntryBlockWithContext[];
  handleUserQuery: (conversation: EntryBlockWithContext[]) => Promise<void>;
  clearConversation: () => void;
  isLoadingEntries?: boolean;
  navigateToDailyEntry?: () => void;
  customClass?: string;
  placeholder?: string;
}) => {
  const {
    navigateToDailyEntry,
    isLoadingEntries,
    conversation,
    clearConversation,
    handleUserQuery,
    customClass,
    placeholder
  } = props;

  const [userQuery, setUserQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const convoBoxRef = useRef<HTMLDivElement | null>(null);

  // scroll to top
  useEffect(() => {
    if (convoBoxRef.current) {
      const div = convoBoxRef.current;
      smoothScrollTo(div, div.scrollHeight - div.clientHeight, 500); // 500ms animation
    }
  }, []);

  const handleNewPrompt = async () => {
    if (!userQuery) {
      window.alert('The prompt cannot be empty 🫠⁭');
      return;
    }

    try {
      setLoading(true);
      const newConversation = [
        ...conversation,
        { role: 'user', content: userQuery, context: [] } as EntryBlockWithContext
      ];
      await handleUserQuery(newConversation);
      setUserQuery('');
    } catch (error) {
      window.alert('Error connecting to AI. Please try again. Sometimes the servers are slow 🐢');
    }
    setLoading(false);
  };

  return (
    <div className={`home__prompt-container ${customClass || ''}`}>
      <div className="gpt-prompt-card__fixed-button-container">
        <button
          disabled={loading}
          className={`clear-conversation-button text text--caption`}
          onClick={clearConversation}
        >
          clear
        </button>
        {navigateToDailyEntry && (
          <CreateNewEntryButton
            btnText="Journal from prompt"
            className={`create-entry-button text text--caption text--bold ${conversation.length <= 3 ? 'opaque' : ''}`}
            navigateToDailyEntry={navigateToDailyEntry}
            disabled={loading || isLoadingEntries || conversation.length <= 3}
          />
        )}
      </div>

      <div className="conversation-container conversation-container--gpt-prompt-card" ref={convoBoxRef}>
        {conversation.map((item, i) => {
          return item.role === 'assistant' ? (
            <AssistantPill value={item.content} key={i} context={item.context} conversation={conversation} />
          ) : (
            <UserPill value={item.content} key={i} />
          );
        })}
        {loading && (
          <div className="flex-row-start flex-gap-4">
            <img
              style={{ opacity: '0.6', height: '24px', width: '24px' }}
              alt="logo"
              className="logo-img blinking-element"
              src={simpleLogo}
            ></img>
            <div className="text text--paragraph-3 text--primary-300">Thinking...</div>
          </div>
        )}
      </div>
      <div className="user-conversation-entry-container">
        <Form.Control
          onChange={(event) => {
            setUserQuery(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleNewPrompt();
            }
          }}
          className="user-conversation-entry"
          placeholder={placeholder || 'Write here...'}
          value={userQuery}
        />
        <button
          className="generate-prompt-button text text--paragraph-1 text--bold"
          disabled={loading}
          onClick={handleNewPrompt}
        >
          Generate
        </button>
      </div>
    </div>
  );
};

export { GptPromptCard, ChatWithJournalCard };
