import React, { useState } from 'react';
import './chatWithJournal.css';
import '../../css/App.css';
import AboutExploreModal from './AboutChatModal';
import Navigation from '../../components/Nav/Nav';
import { ChatWithJournalCard } from '../home/GptPromptCard';
import { EntryBlockWithContext } from '../../components/calendar/types';
import { chatWithJournal } from '../../api';
import PremiumUpsellModal from '../../components/PremiumUpsellModal';
import { userIsFree } from '../../util/subscription';
import { useGeneralContext } from '../../context';
import config from '../../config';

const ChatWithJournal = () => {
  const context = useGeneralContext();
  const { subscriptionStatus } = context;
  const [showModal, setShowModal] = useState(false);
  const [showExploreBlockedModal, setShowExploreBlockedModal] = useState(false);
  const initConversation: EntryBlockWithContext[] = [
    { role: 'assistant', content: 'Hi there, I am a reflection of your entire journal', context: [] }
  ];

  const savedConversation = sessionStorage.getItem('conversation');
  let savedConvoParsed: EntryBlockWithContext[] | null = null;
  if (savedConversation !== null) {
    try {
      savedConvoParsed = JSON.parse(window.atob(savedConversation));
    } catch (err) {}
  }
  const [conversation, setConversation] = useState<EntryBlockWithContext[]>(savedConvoParsed || initConversation);

  const handleUserQuery = async (convo: EntryBlockWithContext[]) => {
    if (userIsFree(subscriptionStatus)) {
      setShowExploreBlockedModal(true);
      return;
    }
    // query journal with conversation and journal context
    const response = await chatWithJournal({ conversation: convo.slice(initConversation.length) });
    sessionStorage.setItem(
      'conversation',
      window.btoa(JSON.stringify([...initConversation, ...response.data.conversation]))
    );
    setConversation([...initConversation, ...response.data.conversation]);
  };

  return (
    <div className="home__container">
      <Navigation />
      <PremiumUpsellModal
        showModal={showExploreBlockedModal}
        setShowModal={setShowExploreBlockedModal}
        message={`Upgrade to Premium (${config.freeTrialPeriodInDays} day free trial included) to unlock unlimited conversation with your own journal.`}
      />
      <AboutExploreModal showModal={showModal} setShowModal={setShowModal} />
      <div className="explore__container">
        <div className="explore__wrapper">
          <div className="text text--regular-weight text--heading-2 explore__main-title">Chat with your Journal</div>
          <div className="chat-with-journal-wrapper">
            <ChatWithJournalCard
              conversation={conversation}
              handleUserQuery={handleUserQuery}
              clearConversation={() => {
                sessionStorage.removeItem('conversation');
                setConversation(initConversation);
              }}
              customClass="prompt-card-box-shadow"
              placeholder="Ask me anything ..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChatWithJournal;
