import Navigation from '../../components/Nav/Nav';
import { Cta, Footer } from '../../LandingPage';
import lisbon from '../../resources/lisbon.jpeg';
import tony2 from '../../resources/tony-3.jpeg';
import nuno from '../../resources/nuno.jpeg';
import team from '../../resources/team.png';
import anakin from '../../resources/anakin.jpg';

import './aboutUs.css';
import { useMediaQuery } from 'react-responsive';

const AboutUs = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });
  return (
    <div className="about-us-container">
      <Navigation />
      <div className="about-us-wrapper">
        <div className="about-us-text-wrapper">
          {isTabletOrMobile ? (
            <>
              <h3 className="text text--caption text--gradient-color">CHECK US OUT ON PRODUCT HUNT</h3>
              <a
                href="https://www.producthunt.com/posts/jumble-journal?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-jumble&#0045;journal"
                target="_blank"
                rel="noreferrer"
                style={{ alignSelf: 'center' }}
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=422767&theme=light"
                  alt="Jumble&#0032;Journal - AI&#0032;Copilot&#0032;🤖&#0032;for&#0032;journaling&#0032;and&#0032;introspection&#0032;✍😌 | Product Hunt"
                  style={{ width: '250px', height: '54px' }}
                  width="250"
                  height="54"
                />
              </a>
            </>
          ) : (
            <>
              <h3 className="text text--caption text--gradient-color">PRODUCT DEMO</h3>
              <iframe
                style={{ border: 'none', alignSelf: 'center' }}
                src="https://cards.producthunt.com/cards/posts/422767?v=1"
                width="500"
                height="405"
                title="producthunt"
                frameBorder="0"
                scrolling="no"
                allowFullScreen
              ></iframe>
            </>
          )}
          <h3 className="text text--caption text--gradient-color">ABOUT JUMBLE</h3>
          <p className="about-us-title text text--subheading text--italic text--primary-900">
            "What are my unrealized intentions and goals?"
          </p>
          <p className="about-us-title text text--subheading text--italic text--primary-900">
            "What have I expressed the most joy about this year?"
          </p>
          <p className="about-us-title text text--subheading text--italic text--primary-900">
            "What are my dreams telling me?"
          </p>
          <div className="about-us__paragraph">
            <p className="text text--heading-4-small text--primary-900">
              Your journal contains the answers to these questions. With Jumble, you can have this conversation, and get
              real answers.
            </p>
            <p className="text text--heading-4-small text--primary-900">
              Journaling is a life changing activity, but it's only the start. There is untapped potential locked within
              the pages of your journal. In fact, your journal is a treasure trove of potentially life changing ideas.
              Jumble surfaces these hidden ideas, insights, dreams, intentions, beliefs, and more so you can reflect and
              feel the nostalgia of past moments.
            </p>
          </div>
          <p className="text text--heading-4-small text--primary-900">
            Our goal is to empower you to grow, thrive, and be happy.
          </p>
          <h3 className="text text--caption text--gradient-color">ABOUT THE TEAM</h3>

          {isTabletOrMobile ? (
            <div className="flex-column-centered flex-gap-32">
              <img alt="founder-tony" className="img_center" src={tony2}></img>{' '}
            </div>
          ) : (
            <img alt="jumble-team" className="img_center-large" src={team}></img>
          )}

          <p className="text text--heading-4-small text--primary-900">
            I'm Tony, the founder of Jumble Journal. I'm a builder and lifelong journaler. Jumble is a reflection of my
            journaling and retrospection process developed over twenty years and thousands of conversations.
          </p>
          {isTabletOrMobile && (
            <div className="flex-column-centered flex-gap-32">
              <img alt="founder-nuno" className="img_center" src={nuno}></img>
            </div>
          )}
          <p className="text text--heading-4-small text--primary-900">
            Nuno believed in Jumble from day one and brings 30 years of product expertise, UX, and business development.
            Jumble's vibe and UI are Nuno's craft.
          </p>
          <p className="text text--heading-4-small text--primary-900">
            We are supported by a dynamic team that shares in the belief that journaling improves the world.
          </p>
          <p className="text text--heading-4-small text--primary-900">
            What started as a security engineering side project is now the platform of choice for thousands of
            journalers. Over 5 million words have been written on Jumble Journal, and 100,000 insights surfaced. As seen
            on Product Hunt and trusted by Draper University.
          </p>
          <p className="text text--heading-4-small text--primary-900">
            We hope you will join the journaling movement. Good things happen to those who journal!
          </p>
        </div>
      </div>
      <Cta />
      <Footer />
    </div>
  );
};

export default AboutUs;
