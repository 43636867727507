import React from 'react';
import '@fontsource/inter';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroup } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import 'react-datepicker/dist/react-datepicker.css';
import '../../css/App.css';
import '../../css/Font.css';
import './retrospective.css';
import { CATEGORY_LABELS } from './types';
import { useGeneralContext } from '../../context';
import { userIsFree } from '../../util/subscription';

const NuggetCategoryFilter = (props: {
  categoryIndex: number;
  setCategoryIndex: React.Dispatch<React.SetStateAction<number>>;
  setShowPremiumModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { categoryIndex, setCategoryIndex, setShowPremiumModal } = props;
  const { subscriptionStatus } = useGeneralContext();

  return (
    <Dropdown
      size="md"
      as={ButtonGroup}
      onSelect={(eventKey) => {
        if (userIsFree(subscriptionStatus)) {
          setShowPremiumModal(true);
          return;
        }
        const clickedCategoryIndex: any = eventKey;
        if (!clickedCategoryIndex) {
          return;
        }
        setCategoryIndex(clickedCategoryIndex as number);
      }}
    >
      <Dropdown.Toggle id="dropdown-basic">
        {CATEGORY_LABELS[categoryIndex].label} <FontAwesomeIcon icon={faAngleDown} style={{ marginLeft: '.6em' }} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {CATEGORY_LABELS.map((value, index) => {
          return (
            <Dropdown.Item eventKey={index} key={index.toString()} active={index === categoryIndex}>
              {value.label}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NuggetCategoryFilter;
