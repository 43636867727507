import React, { useRef, useEffect } from 'react';
import '../../css/App.css';
import '../../css/Font.css';
import './jumbleModal.css';

const JumbleModal = (props: { showModal: boolean; closeModal: () => void; modalSections: (() => JSX.Element)[] }) => {
  const { showModal, closeModal, modalSections } = props;

  const modalRef = useRef<HTMLDivElement>(null);

  // close modal when clicking outside of it
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeModal]);

  // scroll to top of modal when it opens
  useEffect(() => {
    const modal = document.querySelector('.modal-content');
    if (modal && showModal) {
      modal.scrollTop = 0;
    }
  }, [showModal]);

  return (
    <div className={`plan-form__modal-backdrop ${showModal ? 'active' : ''}`}>
      <div className="modal-content" ref={modalRef}>
        {showModal &&
          modalSections.map((section, index) => {
            return (
              <div key={`modal-section-${index}`} className="plan-form-container">
                {section()}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default JumbleModal;
