export type S3Entry = { entryName: string; imported: boolean; tags: string[] };

export type TransformEntriesParams = {
  entries: S3Entry[];
  allTags: string[];
};

export type TagMetadata = { entryNames: string[]; active: boolean };
export type Tags = { [tag: string]: TagMetadata };

export type EntryBlock = {
  role: 'user' | 'assistant';
  content: string;
};

export type EntryBlockWithContext = EntryBlock & { context: string[] };

export type EntryState = EntryFromS3 & {
  name: string;
  tags: string[];
};

export type EntryStates = EntryState[];

export type EntryFromS3 = {
  blocks: EntryBlock[];
  imported: boolean;
};

export const isEntryStates = (entryStates: any): entryStates is EntryStates => {
  return (
    Boolean(entryStates) &&
    typeof entryStates === 'object' &&
    entryStates instanceof Array &&
    entryStates.every((entryState: any) => {
      return isEntryState(entryState);
    })
  );
};

export const isEntryState = (entryState: any): entryState is EntryState => {
  return (
    'blocks' in entryState &&
    'imported' in entryState &&
    'name' in entryState &&
    'tags' in entryState &&
    entryState.blocks instanceof Array &&
    entryState.blocks.every((block: any) => isEntryBlock(block))
  );
};

export const isEntryBlock = (block: any): block is EntryBlock => {
  return 'role' in block && 'content' in block;
};

export const isEntryFromS3 = (entry: any): entry is EntryFromS3 => {
  return (
    'blocks' in entry &&
    'imported' in entry &&
    entry.blocks instanceof Array &&
    entry.blocks.every((block: any) => isEntryBlock(block))
  );
};
