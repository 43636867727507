import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useGeneralContext } from '../context';

const PremiumUpsellModal = (props: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onUpgradeClick?: () => void;
  message?: string;
}) => {
  const context = useGeneralContext();
  const { setPlansModalOpen } = context;
  const { showModal, setShowModal, message, onUpgradeClick } = props;

  const handleCancel = () => {
    setShowModal(false);
  };
  return (
    <Modal show={showModal} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>THIS IS A PREMIUM FEATURE</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message ||
          'Get access to journaling assistance and AI assisted journal exploration by upgrading to Jumble Premium!'}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setShowModal(false);
          }}
          variant="secondary"
        >
          Close
        </Button>
        <Button
          onClick={() => {
            setShowModal(false);
            if (onUpgradeClick !== undefined) {
              return onUpgradeClick();
            } else {
              setPlansModalOpen(true);
            }
          }}
          variant="primary"
        >
          Unlock
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PremiumUpsellModal;
