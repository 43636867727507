enum Category {
  insight = 'insight',
  intention = 'intention',
  belief = 'belief',
  question = 'question',
  milestone = 'milestone',
  gratitude = 'gratitude',
  idea = 'idea',
  dream = 'dream',
  principle = 'principle',
  desire = 'desire'
}

export type CategoryKey = keyof typeof Category;

export const categoryKeys: CategoryKey[] = Object.keys(Category) as CategoryKey[];

export const CATEGORY_LABELS = [
  { key: 'insight', label: 'Deep Insights', color: '#bae3ba' },
  { key: 'intention', label: 'Plans and Intentions', color: '#f58220' },
  { key: 'belief', label: 'Personal Beliefs', color: '#89CFF0' },
  { key: 'question', label: 'Questions', color: '#b5b5f5' },
  { key: 'milestone', label: 'Life Events and Milestones', color: '#00fbff' },
  { key: 'gratitude', label: 'Thanks and Gratitude', color: '#FFA07A' },
  { key: 'idea', label: 'Great Ideas', color: '#198501' },
  { key: 'dream', label: 'Dream Journal', color: '#D8BFD8' },
  { key: 'principle', label: 'Principles to Live By', color: '#4943f7' },
  { key: 'desire', label: 'Desires and Goals', color: '#FF7F7F' }
] as {
  key: CategoryKey;
  label: string;
  color: string;
}[];

export type Nugget = {
  entryName: string;
  category: CategoryKey;
  content: string;
  tags: string[];
};

export type Entry = {
  entryName: string;
  tags: string[];
};

export const isNugget = (nug: any): nug is Nugget => {
  return (
    nug.entryName !== undefined && nug.category !== undefined && nug.content !== undefined && nug.tags !== undefined
  );
};

export const areNuggets = (nugs: any): nugs is Nugget[] => {
  return nugs.every((nug: any) => isNugget(nug));
};
