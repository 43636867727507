const DAY_OF_WEEK_STRINGS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MONTH_STRINGS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const getZeroBasedMonthIndexFromDateObject = (date: Date) => date.getMonth();

const getYearFromDateObject = (date: Date) => date.getFullYear();

/**
 * Returns year, monthIndex (january = 0) and actual day
 * therefore the dayIndex is one less then the day returned by this fxn
 * @param date
 * @returns
 */
const getDayMonthYearFromDate = (date: Date) => ({
  day: date.getDate(),
  month: date.getMonth(),
  year: date.getFullYear()
});

const browserTimestampJournalEntryFormat = (date?: Date) => {
  const localDateTime = date || new Date();
  const unixLocal = localDateTime.getTime() - localDateTime.getTimezoneOffset() * 60 * 1000;
  const result = new Date(unixLocal)
    .toISOString()
    .replace(/T/, '')
    .replace(/\./, '')
    .replace(/-/g, '')
    .replace(/:/g, '')
    .replace(/Z/, '');
  return result;
};

const sameDay = (
  date: Date | { day: number; month: number; year: number },
  entryName: string | { day: number; month: number; year: number }
) => {
  if (!entryName || !date) return false;
  let todayDate = date instanceof Date ? getDayMonthYearFromDate(date) : date;

  const entryDate =
    typeof entryName === 'string'
      ? getYearMonthDayNumbersFromCreationDateString(entryName)
      : (entryName as { day: number; month: number; year: number });
  return entryDate.year === todayDate.year && entryDate.month === todayDate.month && entryDate.day === todayDate.day;
};

const getDaysInMonth = (month: number, year: number) => {
  return new Date(year, month + 1, 0).getDate();
};

const getDayStringFromCreationDate = (creationDate: string) => {
  const day = getDayIndexFromCreationDay(creationDate);
  return DAY_OF_WEEK_STRINGS[day];
};

const getDayIndexFromCreationDay = (creationDate: string) => {
  return getDateUtcFromCreationDateString(getYearMonthDayNumbersFromCreationDateString(creationDate)).getUTCDay();
};

const getYearMonthDayNumbersFromCreationDateString = (creationDate: string) => {
  return {
    year: Number(creationDate.slice(0, 4)),
    month: Number(creationDate.slice(4, 6)) - 1,
    day: Number(creationDate.slice(6, 8))
  };
};

const getNumberOfDaysSinceCreationDateString = (creationDate: string) => {
  const { year, month, day } = getYearMonthDayNumbersFromCreationDateString(creationDate);
  const today = new Date();
  const past = new Date(year, month, day);
  const diff = Math.abs(today.getTime() - past.getTime());
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
  return days;
};

/**
 * @param {String} creationDate Local time in format: YYYYMMDDHHMMSSmmm
 * @returns {String} 1 of August 2022
 */
const getDateStringFromCreationDate = (creationDate: string) => {
  const dateUtc = getDateUtcFromCreationDateString(getYearMonthDayNumbersFromCreationDateString(creationDate));
  const dayOfMonth = dateUtc.getUTCDate();
  const year = dateUtc.getUTCFullYear();
  const month = MONTH_STRINGS[dateUtc.getUTCMonth()];
  return `${month} ${dayOfMonth} ${year}`;
};

const getThreeLetterMonthFromIndex = (monthIndex: number) => {
  return MONTH_STRINGS[monthIndex].slice(0, 3).toUpperCase();
};

const getDateUtcFromCreationDateString = ({ year, month, day }: { year: number; month: number; day: number }) =>
  new Date(Date.UTC(year, month, day));

/**
 * @param {String} creationDate Local time in format: YYYYMMDDHHMMSSmmm
 */
const getTimeFromCreationDate = (creationDate: string) => {
  let hour = Number(creationDate.slice(8, 10));
  const minute = creationDate.slice(10, 12);
  let am_pm = 'AM';
  if (hour > 12) {
    hour = hour - 12;
    am_pm = 'PM';
  }
  return `${hour}:${minute} ${am_pm}`;
};

// chatgpt
const daysAgo = (days: number, initDate?: Date) => {
  let date = initDate ? new Date(initDate.getTime()) : new Date();
  date.setDate(date.getDate() - days);
  return date;
};

const daysAhead = (days: number, initDate?: Date) => daysAgo(-days, initDate);

const getDayDateTimeFromEntryDateTitle = (entryDateTitle: string) => {
  const day = getDayStringFromCreationDate(entryDateTitle).slice(0, 3);
  const date = getDateStringFromCreationDate(entryDateTitle).slice(0, -4);
  const time = getTimeFromCreationDate(entryDateTitle);
  return { day, date, time };
};

export {
  browserTimestampJournalEntryFormat,
  getDayStringFromCreationDate,
  getDateStringFromCreationDate,
  getTimeFromCreationDate,
  getDaysInMonth,
  getZeroBasedMonthIndexFromDateObject,
  getYearFromDateObject,
  getYearMonthDayNumbersFromCreationDateString,
  getDayMonthYearFromDate,
  getThreeLetterMonthFromIndex,
  daysAgo,
  sameDay,
  daysAhead,
  getNumberOfDaysSinceCreationDateString,
  getDayDateTimeFromEntryDateTitle
};
