import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Info } from 'react-feather';
import { Api, updateUserData } from '../../api';
import {
  getNotificationSetting,
  unsubscribeUserNotification,
  updateUserNotificationSetting
} from '../../api/notificationsp-api';
import { useAuthContext, useGeneralContext } from '../../context';
import '../../css/App.css';
import PersonalJumbleEmail from './PersonalJumbleEmail';
import { UpgradePremiumButton, UpgradeToPremiumButton } from './PremiumButton';
import './settings.css';
import Navigation from '../../components/Nav/Nav';

function Settings() {
  const context = useGeneralContext();
  const authContext = useAuthContext();
  const { user } = authContext;
  const { nickname, setFeedbackModalOpen, setPlansModalOpen, subscriptionStatus } = context;
  const [showModal, setShowModal] = useState(false);
  const location = useLocation<{
    destination: string;
  }>();

  const isPremium = subscriptionStatus && subscriptionStatus !== 'free';
  const isYearly = subscriptionStatus === 'premium_yearly';
  const isLifetime = subscriptionStatus === 'lifetime';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location.state && location.state.destination === 'choose-plan') {
      setPlansModalOpen(true);
    }
  }, [location.state, setPlansModalOpen]);

  // email/pwd and google create different user objects
  const userEmail = user?.attributes?.email || (user as any)?.signInUserSession?.idToken?.payload?.email || null;
  return (
    <div className={`home__container`}>
      <InfoModal showModal={showModal} setShowModal={setShowModal} />
      <Navigation />
      <div className={`settings__container`}>
        <div className="settings__wrapper">
          <div className="settings__header">
            <div className="settings__header-flex">
              <div className="text text--heading-2 settings__main-title">Settings</div>
              <div className="settings__header-cta">
                <div className="settings__header-cta-content">
                  <div className="settings__header-cta-content-container">
                    <div className="flex-column-left flex-gap-16">
                      <div className="text text--header-4">
                        {`You are currently on the ${
                          isPremium
                            ? isYearly
                              ? 'annual premium plan'
                              : isLifetime
                              ? 'lifetime premium plan'
                              : 'monthly premium plan'
                            : 'free plan'
                        }`}
                      </div>
                    </div>
                    <div className="settings__header-cta-buttons-container">
                      <button
                        onClick={() => {
                          setFeedbackModalOpen(true);
                        }}
                        className="settings__chat-button"
                      >
                        <MailIcon />
                        <div className="text text--paragraph-2 text--bold text--gradient-color">Chat with us</div>
                      </button>
                      {isPremium ? (
                        isYearly || isLifetime ? (
                          <></>
                        ) : (
                          <UpgradeToPremiumButton
                            key="UpgradeToPremiumButton"
                            header=""
                            buttons={[
                              {
                                text: 'Upgrade to Annual',
                                planId: 'premium_yearly',
                                isUpgrade: true,
                                classNames: 'upgrade-button-1 text text--paragraph-2 text--bold text--white'
                              }
                            ]}
                          />
                        )
                      ) : (
                        <button
                          className="upgrade-button-1 text text--paragraph-2 text--bold text--white"
                          onClick={() => setPlansModalOpen(true)}
                        >
                          <div>Upgrade to premium</div>
                        </button>
                      )}
                      {/* <button className="upgrade-button-1 text text--paragraph-2 text--bold text--white">
                        {`Upgrade to ${isPremium ? 'Annual' : 'Premium'}`}
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="settings__section">
            <div className="settings__section-title-container">
              <div className="text text--paragraph-2 text--primary-700">You are logged in as</div>
              <div className="flex-row-start">
                <div className="text text--caption text--neutral-500">{userEmail}</div>
              </div>
            </div>
          </div>
          <div className="settings__section">
            <div className="settings__section-title-container">
              <div className="text text--paragraph-2 text--primary-700">Name</div>
              <div className="flex-row-start">
                <div className="text text--caption text--neutral-500">{`What should we call you${
                  nickname ? ', ' : ''
                }`}</div>
                <div style={{ marginLeft: '4px' }} className="text text--caption text--neutral-900">
                  {nickname}
                </div>
                <div className="text text--caption text--neutral-500">?</div>
              </div>
            </div>
            <div className="settings__section-update-container">
              <Nickname />
            </div>
          </div>
          <div className="settings__section">
            <div className="settings__section-title-container">
              <div className="text text--paragraph-2 text--primary-700">Email subscription</div>
              <div className="flex-row-start">
                <div className="text text--caption text--neutral-500">
                  Subscribe to receive product updates, special offers, and rad content
                </div>
              </div>
            </div>
            <div className="settings__section-update-container">
              <SubscribeEmailList />
            </div>
          </div>
          <div className="settings__section">
            <div className="settings__section-title-container">
              <div className="flex-row-start">
                <div className="text text--paragraph-2 text--primary-700">Your Jumble email</div>{' '}
                <Info
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className="clickable-icon"
                />
              </div>
              <div className="flex-row-start">
                <div className="text text--caption text--neutral-500">
                  Journal by hand. Email a clear image of the entry to your personal Jumble email address and it will be
                  added to your journal.
                </div>
              </div>
            </div>
            <div className="settings__section-update-container">
              <PersonalJumbleEmail />
            </div>
          </div>
          <div className="settings__section">
            <div className="settings__section-title-container">
              <div className="text text--paragraph-2 text--primary-700">Subscription plan</div>
              <div className="flex-row-start">
                <div className="text text--caption text--neutral-500">
                  {isPremium
                    ? isYearly
                      ? 'View details and manage your subscription'
                      : isLifetime
                      ? 'You have Jumble premium for life'
                      : 'Upgrade to annual and save 25%'
                    : 'Get access to the journal assistant, reMarkable integration, AI assisted self exploration and more by upgrading to Premium!'}
                </div>
              </div>
            </div>
            <div className="settings__section-update-container">
              <UpgradePremiumButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const MailIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M2.66732 3.16602H13.334C14.0673 3.16602 14.6673 3.76602 14.6673 4.49935V12.4993C14.6673 13.2327 14.0673 13.8327 13.334 13.8327H2.66732C1.93398 13.8327 1.33398 13.2327 1.33398 12.4993V4.49935C1.33398 3.76602 1.93398 3.16602 2.66732 3.16602Z"
        stroke="url(#paint0_linear_6643_17421)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6673 4.5L8.00065 9.16667L1.33398 4.5"
        stroke="url(#paint1_linear_6643_17421)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6643_17421"
          x1="8.00065"
          y1="3.16601"
          x2="28.5188"
          y2="34.0732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7543E8" />
          <stop offset="1" stopColor="#FF5B5B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6643_17421"
          x1="8.00065"
          y1="4.5"
          x2="13.2186"
          y2="22.4657"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7543E8" />
          <stop offset="1" stopColor="#FF5B5B" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const UNSUBSCRIBE_STRING = 'Unsubscribe from Reminders';
const options = new Array(25)
  .fill('')
  .map((_v, i) => (i === 0 ? 'MIDNIGHT' : i === 12 ? 'MIDDAY' : i < 12 ? `${i} AM` : `${i - 12} PM`));
options[24] = UNSUBSCRIBE_STRING;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TimeSelect = () => {
  const [notificationSetting, setNotificationSetting] = useState(24);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('When do you want your daily email reminder to go out?');

  useEffect(() => {
    const controller = new AbortController();
    const retrieveNotificationSetting = async () => {
      setLoading(true);
      try {
        const data = await getNotificationSetting(controller.signal);
        setNotificationSetting(data.time || 24);
      } catch (error: any) {
        // request was cancelled
        if (error.code === 'ERR_CANCELED') {
          console.log('request cancelled');
          return;
        }
        alert(
          'Failed to retrieve notification setting. Please try again and reach out to support@jumblejournal.org if the problem persists'
        );
      }
      setLoading(false);
    };
    retrieveNotificationSetting();
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      <div className="text text--bold text--paragraph-2 text--neutral-500">{title}</div>
      <div className="settings__selector-wrapper">
        {loading ? (
          <div style={{ gridColumn: 2 }}>
            <span
              style={{ textAlign: 'center', height: '1em', width: '1em' }}
              className="spinner-border"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        ) : (
          <Form.Select
            onMouseEnter={(e) => ((e.target as any).style.cursor = 'pointer')} // Change cursor to pointer on hover
            onMouseLeave={(e) => ((e.target as any).style.cursor = 'default')} // Revert cursor to default when leaving
            value={options[notificationSetting]}
            aria-label="Notification Time Select"
            onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
              event.preventDefault();
              const choice = event.target.value;
              setLoading(true);
              try {
                if (choice === UNSUBSCRIBE_STRING) {
                  await unsubscribeUserNotification();
                  setTitle('✅ You will not be sent a daily reminder.');
                  setNotificationSetting(24);
                } else {
                  const time = options.indexOf(choice);
                  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                  await updateUserNotificationSetting({ time, timezone });
                  setTitle(`✅ You will be emailed a daily reminder at ${options[time]} Local Time.`);
                  setNotificationSetting(time);
                }
              } catch (error) {
                alert(
                  'Failed to update notification setting. Please try again and reach out to support@jumblejournal.org if the problem persists'
                );
              }
              setLoading(false);
            }}
          >
            {options.map((v, i) => (
              <option key={`option-${i}`}>{v}</option>
            ))}
          </Form.Select>
        )}
      </div>
    </>
  );
};

const resetForm = () => {
  const element = document.getElementById('account-creation-form') as HTMLFormElement;
  if (element !== null) {
    element.reset();
  }
};

const SubscribeEmailList = () => {
  const [loading, setLoading] = useState(false);
  const context = useGeneralContext();
  const { setSubscribed, subscribed } = context;

  const handleCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
    if (event === undefined) {
      return;
    }
    const isChecked = event.target.checked;
    setSubscribed(isChecked);
    try {
      setLoading(true);

      if (isChecked) {
        await Api.post('/subscribe');
      } else {
        await Api.post('/unsubscribe');
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSubscribed(!isChecked);
      window.alert(
        'There was a problem updating subscription setting. Please try again. If the issue persists, let us know at support@jumblejournal.org.'
      );
    }
  };

  return (
    <div className="flex-column-left">
      <Form.Check
        disabled={loading}
        type="switch"
        id="custom-switch"
        checked={subscribed}
        onChange={handleCheckboxChange}
        onMouseEnter={(e) => ((e.target as any).style.cursor = 'pointer')} // Change cursor to pointer on hover
        onMouseLeave={(e) => ((e.target as any).style.cursor = 'default')} // Revert cursor to default when leaving
      />
      <div className={`text text--paragraph-1 text--semi-bold ${loading ? 'text--neutral-500' : 'text--neutral-700'}`}>
        {loading ? 'updating ...' : subscribed ? 'Subscribed' : 'Unsubscribed'}
      </div>
    </div>
  );
};

const Nickname = () => {
  const context = useGeneralContext();
  const { setNickname, nickname } = context;
  const [isLoading, setIsLoading] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1250px)' });

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());

    const newNickname = formDataObj.nickname as string;

    if (newNickname === nickname) {
      resetForm();
      return;
    }
    setIsLoading(true);

    if (newNickname.length > 32) {
      window.alert('Sorry, this nickname is too long. Try something shorter.');
      setIsLoading(false);
      return;
    }

    try {
      await updateUserData({ nickname: newNickname });
      setNickname(newNickname.toString());
    } catch (error) {
      alert(
        'Failed to update nickame. Please try again and reach out to support@jumblejournal.org if the problem persists'
      );
    }

    setIsLoading(false);
    resetForm();
  };

  return (
    <div className="">
      <div className="settings__selector-wrapper">
        <Form id="account-creation-form" onSubmit={handleSubmit}>
          <InputGroup className="mb-3">
            <Form.Control
              aria-label="Update nickname form"
              placeholder="change your name..."
              name="nickname"
              id="nickname"
            />
            {isTabletOrMobile ? (
              <></>
            ) : (
              <Button
                className="settings__update-nickname-button"
                type="submit"
                variant="outline-secondary"
                id="button-addon2"
              >
                {isLoading ? (
                  <span
                    style={{ marginLeft: '4px', marginRight: '8px', height: '16px' }}
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <></>
                )}
                {isTabletOrMobile ? '' : 'Update Nickname'}
              </Button>
            )}
          </InputGroup>
        </Form>
      </div>
    </div>
  );
};

const InfoModal = (props: { showModal: boolean; setShowModal: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { showModal, setShowModal } = props;

  const handleCancel = () => {
    setShowModal(false);
  };
  return (
    <Modal show={showModal} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>About Offline Journaling ✍</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text text--bold">
          (New feature ✨) You can upload handwritten entries with your personal Jumble Email Address.
        </p>
        <p className="text">
          Take a picture of your journal entry and send it to your personal Jumble Email Address as an attachment.
          Jumble will do the rest. You can add up to five image per email. Make sure the writing is legible and the
          image is centered and well lit. We currently support PNG (.png), JPEG (.jpeg and .jpg), WEBP (.webp), and
          non-animated GIF (.gif).
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default Settings;
