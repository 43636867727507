import { Api } from '../api';
import { SupportedCurrencyCode } from '../views/choose-plan/data';
import { SubscriptionPlan } from '../views/settings/types';

export const createCheckoutSession = async (props: {
  planId: SubscriptionPlan | 'lifetime';
  isUpgrade: boolean;
  currency: SupportedCurrencyCode;
}) => {
  const { planId, isUpgrade, currency } = props;
  return Api.post(`create-checkout-session?product=${planId}&upgrade=${isUpgrade}&currency=${currency}`);
};

export const createCustomerPortal = async (props: { stripeCustomerId: string }) => {
  const { stripeCustomerId } = props;
  return Api.post(`create-customer-portal?customerId=${stripeCustomerId}`);
};
