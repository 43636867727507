import { ResponsiveContainer, Treemap } from 'recharts';
import '../../css/App.css';
import '../exploration/chatWithJournal.css';
import './retrospective.css';
import { CATEGORY_LABELS, CategoryKey, Nugget } from './types';
import { useEffect, useState } from 'react';
import JumbleModal from '../../components/jumbleModal/JumbleModal';
import { getDayDateTimeFromEntryDateTitle } from '../../util/dateTime';
import { loadExistingEntry } from '../../components/calendar/calendar';
import { EntryStates, isEntryStates } from '../../components/calendar/types';
import { Maximize2, Edit, ArrowLeft } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CustomizedContent = (props: {
  data: { name: string; size: number }[];
  showBackground: boolean;
  handleClick: (name: string) => void;
  root?: any;
  depth?: any;
  x?: any;
  y?: any;
  width?: any;
  height?: any;
  index?: any;
  payload?: any;
  colors?: any;
  rank?: any;
  name?: any;
  fill?: any;
}) => {
  const { handleClick, showBackground, fill, depth, x, y, width, height, name, data } = props;

  const MentorSelectorTooltip = <Tooltip id="mentor-selector-tooltip">{name}</Tooltip>;

  const size = data.find((d) => d.name === name)?.size;
  return (
    <OverlayTrigger placement="auto" overlay={MentorSelectorTooltip}>
      <g>
        {/* this is a background rectangle so the fill color does not overflow over the rounded corners */}
        {showBackground && (
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            style={{
              fill: '#fff' // Background color to match the container background
            }}
          />
        )}
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          rx={10}
          ry={10}
          style={{
            fill,
            stroke: '#fff',
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10),
            cursor: 'pointer'
          }}
          onClick={() => handleClick(name)} // Attach onClick event
        />
        {depth === 1 && width > name.length * 8 ? (
          <text
            className="text text--caption text--regular-weight text--primary-500"
            x={x + width / 2}
            y={y + height / 2 + 7}
            textAnchor="middle"
            fill="#fff"
            style={{ cursor: 'pointer' }}
            onClick={() => handleClick(name)}
          >
            {name}
          </text>
        ) : null}
        {/* number of entries with this tag, upper left hand corner */}
        {depth === 1 && width > 1 && height > 1 && size && size > 2 ? (
          <text className="text text--caption text--primary-900" x={x + 4} y={y + 18}>
            {size}
          </text>
        ) : null}
      </g>
    </OverlayTrigger>
  );
};

const TreeMapWrapper = (params: {
  data: { name: string; size: number }[];
  handleClick: (name: string) => void;
  entryLoading: boolean;
}) => {
  const { data, handleClick, entryLoading } = params;
  const animationDuration = 1000;

  const [showBackground, setShowBackground] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBackground(true); // Show the background rectangle after the animation ends
    }, animationDuration);

    // Cleanup the timeout if the component unmounts or the data changes
    return () => clearTimeout(timer);
  }, [data]);

  const COLORS = CATEGORY_LABELS.map((cat) => cat.color);

  return (
    <ResponsiveContainer width="100%" height={400} className="padding-right-32">
      <Treemap
        width={400}
        height={200}
        data={data}
        dataKey="size"
        nameKey="name"
        aspectRatio={4 / 3}
        stroke="#fff"
        fill="#ac8ef1"
        content={
          <CustomizedContent data={data} colors={COLORS} showBackground={showBackground} handleClick={handleClick} />
        }
        animationDuration={animationDuration}
      ></Treemap>
    </ResponsiveContainer>
  );
};

const TreeMap = (props: {
  nuggets: Nugget[];
  loading: boolean;
  setShowPremiumModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { loading, nuggets } = props;

  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<JSX.Element>(<></>);
  const [entryIndexLoading, setEntryIndexLoading] = useState<number | null>(null);

  const [tagMap, setTagMap] = useState<
    Map<string, { entryName: string; nuggetContent: string; category: CategoryKey }[]>
  >(new Map());
  // const tagMap: Map<string, { entryName: string; nuggetContent: string; category: CategoryKey }[]> = new Map();
  const [treeMapData, setTreeMapData] = useState<{ name: string; size: number }[]>([]);

  useEffect(() => {
    const tempTagMap: Map<string, { entryName: string; nuggetContent: string; category: CategoryKey }[]> = new Map();
    nuggets.forEach((nugget) => {
      nugget.tags.forEach((tag) => {
        if (!tempTagMap.has(tag)) {
          tempTagMap.set(tag, []);
        }
        tempTagMap
          .get(tag)!
          .push({ entryName: nugget.entryName, nuggetContent: nugget.content, category: nugget.category });
      });
    });
    setTagMap(tempTagMap);

    setTreeMapData(
      [...tempTagMap.entries()].map(([tag, entries]) => ({
        name: tag,
        size: entries.length
      }))
    );
  }, [nuggets]);

  const setModalContentToEntryContent = async (params: {
    nuggetName: string;
    day: string;
    date: string;
    time: string;
    entryName: string;
  }) => {
    const { day, date, time, entryName, nuggetName } = params;
    try {
      // handleClick && handleClick();
      const entryStates: EntryStates = [];
      const le = await loadExistingEntry({
        entryName: entryName
      });

      entryStates.push({
        ...le,
        name: entryName
      });
      if (!isEntryStates(entryStates)) {
        const error = `Invalid entry stats: ${JSON.stringify(entryStates)}`;
        console.error(error);
        throw new Error(error);
      }

      // set modal background color to nugget color
      const allSameEntry = entryStates.every((entryState) => entryState.name === entryStates[0].name);
      const dateString = entryStates.length === 1 || allSameEntry ? `${day}, ${date} at ${time}` : `${day}, ${date}`;
      setModalContent(
        <div className="text text--paragraph-1 margin-bottom-16 flex-column-left flex-gap-12">
          <div className="flex-row-start flex-gap-8">
            <button
              onClick={() => setModalContentToTagNuggetContent(nuggetName)}
              className={`text text--caption text--neutral-600 jumble-button-tertiary`}
            >
              <ArrowLeft className="flex-item-aligned-start" size={20} />
            </button>
            <div className="text text--subheading text--bold flex-item-aligned-center">{dateString}</div>
          </div>
          {entryStates.map((entryState) => {
            return (
              <div className="display-inline">{`${entryState.blocks
                .filter((block) => block.role === 'user')
                .map((block) => `${block.content}\n\n`)}`}</div>
            );
          })}
          <button
            onClick={() => openEntryForEditing({ entryName, entryStates })}
            className={`text text--caption text--neutral-700 jumble-button-tertiary flex-item-aligned-end`}
          >
            <Edit size={16} />
          </button>
        </div>
      );
    } catch (error) {
      alert('Error loading entry');
      console.error(error);
    }
  };

  const openEntryForEditing = async (params: { entryName: string; entryStates: EntryStates }) => {
    const { entryName, entryStates } = params;
    return history.push({
      pathname: `/entry/${entryName}`,
      state: {
        entryState: entryStates,
        returnPath: '/retrospective'
      }
    });
  };

  const setModalContentToTagNuggetContent = (name: string) => {
    const entryNames = tagMap.get(name)?.map((e) => e.entryName);
    if (!entryNames) {
      setModalContent(<div>name</div>);
    } else {
      setModalContent(
        <div className="flex-column-centered flex-gap-8">
          <div className="text text--caption text--gradient-color flex-item-aligned-start margin-bottom-16">{name}</div>
          <div className="flex-column-left flex-gap-16">
            {tagMap.get(name)?.map((tagMetadata, index) => {
              const { day, date, time } = getDayDateTimeFromEntryDateTitle(tagMetadata.entryName);
              const year = tagMetadata.entryName.slice(0, 4);
              return (
                <div className="flex-column-left flex-gap-4">
                  <div className="text text--paragraph-1 text--bold text--primary-900">
                    {tagMetadata.category} - {date}, {year}
                  </div>

                  <div key={`${JSON.stringify(tagMetadata)}`} className="flex-row-start flex-gap-4">
                    <div className="text text--paragraph-1 text--primary-900">{tagMetadata.nuggetContent} </div>
                    <button
                      disabled={entryIndexLoading !== null}
                      className={`text text--caption jumble-button-tertiary ${
                        index === entryIndexLoading && 'blinking-element'
                      }`}
                      onClick={async () => {
                        setEntryIndexLoading(index);
                        const dateString = `${day}, ${date}`;
                        await setModalContent(
                          <div className="text text--paragraph-1 margin-bottom-16 flex-column-left flex-gap-12">
                            <div className="text text--subheading text--bold margin-bottom-16">{dateString}</div>
                            <span
                              style={{ marginRight: '32px' }}
                              className="spinner-grow spinner-grow-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </div>
                        );
                        await setModalContentToEntryContent({
                          nuggetName: name,
                          day,
                          date,
                          time,
                          entryName: tagMetadata.entryName
                        });
                        setEntryIndexLoading(null);
                      }}
                    >
                      <Maximize2 size={14} />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    setShowModal(true);
  };

  return (
    <div className="retro__container scrollable-container">
      <div className="flex-row-space-between">
        <div className="text text--caption text--gradient-color">Appendix of Journaling Topics</div>
      </div>
      {loading ? (
        <span
          style={{ height: '5em', width: '5em' }}
          className="spinner-border"
          role="status"
          aria-hidden="true"
        ></span>
      ) : (
        <div className="retro--grid">
          <TreeMapWrapper
            data={treeMapData}
            handleClick={setModalContentToTagNuggetContent}
            entryLoading={setEntryIndexLoading !== null}
          />
        </div>
      )}
      <JumbleModal showModal={showModal} closeModal={() => setShowModal(false)} modalSections={[() => modalContent]} />
    </div>
  );
};

export default TreeMap;
