import React from 'react';
import {
  Activity,
  Home,
  HelpCircle,
  Settings,
  LogOut,
  UserPlus,
  LogIn,
  MessageCircle,
  Info,
  Map,
  CreditCard
} from 'react-feather';

export const getMenuItems = (props: {
  setFeedbackModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPlansModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  history: any;
  loggedIn: boolean;
}) => {
  const { setFeedbackModalOpen, setPlansModalOpen, history, loggedIn } = props;
  return loggedIn
    ? loggedInMenuItems(setFeedbackModalOpen, history)
    : loggedOutMenuItems(setFeedbackModalOpen, setPlansModalOpen);
};

const loggedInMenuItems = (setFeedbackModalOpen: React.Dispatch<React.SetStateAction<boolean>>, history: any) => [
  {
    destination: '/home',
    text: 'Home',
    icon: <Home className="side-nav__item-logo text--primary-800" />
  },
  {
    destination: '/chat',
    text: 'Chat with Journal',
    icon: <Activity className="side-nav__item-logo text--primary-800" />
  },
  {
    destination: '/retrospective',
    text: 'Explore your Journal',
    icon: <Map className="side-nav__item-logo text--primary-800" />
  },
  {
    onClick: () => {
      setFeedbackModalOpen(true);
    },
    text: 'Chat with us',
    icon: <MessageCircle className="side-nav__item-logo text--primary-800" />
  },
  {
    destination: '/settings',
    text: 'Settings',
    icon: <Settings className="side-nav__item-logo text--primary-800" />
  },
  {
    destination: '/home',
    // carefult changing this string. Sorry to self for the hack. See Nav.tsx dependency
    text: 'Log out',
    icon: <LogOut className="side-nav__item-logo text--primary-800" />
  }
];

const loggedOutMenuItems = (
  setFeedbackModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setPlansModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => [
  { text: 'About', destination: '/about', icon: <Info className="side-nav__item-logo text--primary-800" /> },
  { text: 'FAQ', destination: '/faq', icon: <HelpCircle className="side-nav__item-logo text--primary-800" /> },
  {
    text: 'Chat with us',
    onClick: () => {
      setFeedbackModalOpen(true);
    },
    icon: <MessageCircle className="side-nav__item-logo text--primary-800" />
  },
  {
    text: 'Pricing',
    onClick: () => {
      setPlansModalOpen(true);
    },
    icon: <CreditCard className="side-nav__item-logo text--primary-800" />
  },
  {
    text: 'Log in',
    destination: '/home',
    icon: <LogIn size={16} className="side-nav__item-logo text--primary-700" />
  },
  {
    text: 'Get Started',
    destination: '/home',
    icon: <UserPlus size={16} className="side-nav__item-logo text--primary-700" />
  }
];
