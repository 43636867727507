import axios from 'axios';
import { EntryBlock, EntryBlockWithContext } from './components/calendar/types';
import { TagParams } from './components/entryTags/types';
import config from './config';
const { journalAppUri } = config;

export const Api = axios.create({
  baseURL: journalAppUri ? `${journalAppUri}/` : undefined,
  // enable cookies
  // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
  withCredentials: true
});

type UpsertJournalEntryParams = {
  creationDateTitle: string;
  value: string;
  embedEntry: boolean;
};

export const upsertJournalEntry = async (params: UpsertJournalEntryParams) => {
  const { value, creationDateTitle, embedEntry } = params;
  return Api.post('journalentry', {
    value,
    creationDateTitle,
    embedEntry
  });
};

export const retrieveJournalEntry = async ({ entryDateTitle }: { entryDateTitle: string }) => {
  return Api.get(`journalentry/${entryDateTitle}`);
};

export const retrieveSubscription = async () => {
  return Api.get('subscription');
};

export const deleteTag = async (params: TagParams) => {
  const { value: tag, creationDate: entryName } = params;
  try {
    await Api.post('/delete/tag', { tag, entryName });
  } catch (error) {
    console.error(`error deleting tag ${tag} from ${entryName}`, error);
  }
};

export const addTag = async (params: TagParams) => {
  const { value: tag, creationDate: entryName } = params;
  try {
    await Api.post(`/tag`, { tag, entryName });
  } catch (error) {
    console.error(`error adding tag ${tag} to ${entryName}`, error);
  }
};

export const updateUserData = async (params: { entryBackgroundColor?: string; nickname?: string }) => {
  const { entryBackgroundColor, nickname } = params;
  return Api.post(`/userInfo`, { entryBackgroundColor, nickname });
};

export const getUserData = async () => {
  try {
    const response = await Api.get(`/userInfo`);
    if (!response || !response.data || !response.data.settings) {
      return {};
    }
    return response.data;
  } catch (error) {
    console.error('Error retrieving UserData', error);
    return {};
  }
};

export const generateAccountVerificationToken = async (email: string) => {
  try {
    await Api.post(`/generateToken`, { email });
    return true;
  } catch (error) {
    alert('problem generating account verification token, try again later');
    return false;
  }
};

export const verifyVerificationToken = async (token: string) => {
  try {
    await Api.post(`/verifyToken`, { token });
    return true;
  } catch (error) {
    alert('Unable to verify token');
    return false;
  }
};

export const loginUser = async (secretKey: string, email: string) => {
  try {
    return await Api.post('login', {
      secretKey,
      email
    });
  } catch (error) {
    alert('Unable to verify token. If the problem persists please conact support@jumblejournal.org');
    return false;
  }
};

export const contactSupport = async (params: { email: string; subject: string; body: string }) => {
  try {
    return await Api.post('contact', params);
  } catch (error) {
    alert('Unable to send message. Please email us directly at support@jumblejournal.org.');
    return false;
  }
};

export const queryGpt3 = async (params: { prompt: string }) => {
  return Api.post('gpt', params);
};

export const queryGptJournalAssistant = async (params: { prompt: EntryBlock[]; impersonate?: string }) => {
  return Api.post('jumble-bot', params);
};

export const chatWithJournal = async (params: { conversation: EntryBlockWithContext[] }) => {
  const { conversation } = params;
  return Api.post('/journal/query', {
    conversation
  });
};

export const checkMentor = (params: { name: string }) => {
  const { name } = params;
  return Api.get(`/jumble-bot/recognize?name=${encodeURI(name)}`);
};
