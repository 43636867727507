import { Modal } from 'react-bootstrap';

const AboutExploreModal = (props: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { showModal, setShowModal } = props;

  const handleCancel = () => {
    setShowModal(false);
  };
  return (
    <Modal show={showModal} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Chat with your Journal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Here you can learn about yourself by chatting with your own journal. Your journal is a reflection. It's a piece
        of yourself. It is more than just words, it's patterns, hopes, fears and dreams. Dive In!
      </Modal.Body>
    </Modal>
  );
};

export default AboutExploreModal;
