import React from 'react';

type ContextParams = {
  entries: { name: string; imported: boolean }[];
  addEntry: (name: string, imported: boolean) => void; // without tags
  setEntries: (entries: { name: string; imported: boolean }[]) => void;
  deleteEntry: (name: string) => void;
};

const EntryContext = React.createContext<ContextParams | null>(null);

export default EntryContext;
