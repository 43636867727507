import React from 'react';
import './css/App.css';
import Navigation from './components/Nav/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import habitGuyColor from './resources/KB_habitGuyColor.png';
import retroGuyColor from './resources/KB_retroGuyColor.png';
import jumbleManHello from './resources/KB_jumbleManHello.png';
import draperLogo from './resources/draper-logo.png';
import LegalFooter from './components/legalFooter/LegalFooter';
import Carousel from './components/carousel/Carousel';
import { ReactComponent as Clock } from './resources/KB_clock.svg';
import { useMediaQuery } from 'react-responsive';

const CtaButton = () => {
  const history = useHistory();

  return (
    <div className="landingpage__hero-button-container">
      <button
        className="text text--semi-bold text--body-medium button--primary-action link--no-effect-white-text"
        style={{ textAlign: 'center' }}
        onClick={() => {
          history.push('/home');
        }}
      >
        <FontAwesomeIcon icon={faUser as IconProp} style={{ marginRight: '.6em' }} />
        Create Your Free Journal
      </button>
    </div>
  );
};
const Hero = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
  return (
    <div key="hero" className="landingpage__hero">
      <div className="landingpage__hero-left">
        <div className="text text--caption text--bold text--gradient-color landingpage__hero-title">DAILY JOURNAL</div>
        <h1 className="text text--display-2 text--bold text--primary-900 landingpage__hero-text margin-bottom-8">
          Good Things Come to Those Who Journal
        </h1>
        <p className="text text--regular-weight text--paragraph-1 text--primary-900 margin-bottom-32">
          Get the most out of your journaling ritual
        </p>
        <div className="landingpage__cta-wrapper">
          <CtaButton />
        </div>
        <p className="text text--regular-weight text--paragraph-1 text--primary-900 margin-top-8">
          Distraction free journaling insights. Data Privacy. Hakuna Matata 🐗
        </p>
      </div>
      <div className="landingpage__hero-image flex-column-centered flex-gap-20">
        <div className="text text--caption text--bold text--gradient-color landingpage__hero-title">As Seen On</div>
        <a
          href="https://www.producthunt.com/posts/jumble-journal?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-jumble&#0045;journal"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=422767&theme=light"
            alt="Jumble&#0032;Journal - AI&#0032;Copilot&#0032;🤖&#0032;for&#0032;journaling&#0032;and&#0032;introspection&#0032;✍😌 | Product Hunt"
            style={{ width: '250px', height: '54px' }}
            width="250"
            height="54"
          />
        </a>
        <a
          href="https://theresanaiforthat.com/ai/jumble-journal/?ref=featured&v=1248488"
          target="_blank"
          rel="nofollow"
        >
          <img width="250" src="https://media.theresanaiforthat.com/featured3.png" alt="Featured Image" />
        </a>
        <a href="https://draperuniversity.com/" target="_blank" rel="nofollow">
          <img
            width="250"
            src={draperLogo}
            alt="Being A Startup Enthusiast And A Budding Entrepreneur, - Draper University Logo"
          />
        </a>
      </div>
    </div>
  );
};

const PhilosophyTriplet = () => {
  const titleClasses = 'text--semi-bold text--subheading';
  const textClasses = 'text--regular-weight text--paragraph-2';
  const iconClasses = 'landingpage__triplet-icon';
  const clarity = () => (
    <div key="clarity" className="landingpage__triplet-box--1 landingpage__triplet-box">
      {/* <Feather className={iconClasses} /> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="url(#g1)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`feather feather-feather ${iconClasses}`}
      >
        <defs>
          <linearGradient id="g1" x1=".5" x2=".5" y2="1">
            <stop offset=".143" stopColor="#9169ed" />
            <stop offset="1.714" stopColor="#ff5b5b" />
          </linearGradient>
        </defs>
        <path d="M20.24 12.24a6 6 0 0 0-8.49-8.49L5 10.5V19h8.5z"></path>
        <line x1="16" y1="8" x2="2" y2="22"></line>
        <line x1="17.5" y1="15" x2="9" y2="15"></line>
      </svg>
      <p className={titleClasses}>Gain Clarity</p>
      <p className={textClasses}>
        When your thoughts are jumbled, it is difficult to be effective. Journaling helps you organize ideas and focus
        on what really matters.
      </p>
    </div>
  );
  const insight = () => (
    <div key="insight" className="landingpage__triplet-box--2 landingpage__triplet-box">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="url(#g1)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`feather feather-smile ${iconClasses}`}
      >
        <defs>
          <linearGradient id="g1" x1=".5" x2=".5" y2="1">
            <stop offset=".143" stopColor="#9169ed" />
            <stop offset="1.714" stopColor="#ff5b5b" />
          </linearGradient>
        </defs>
        <circle cx="12" cy="12" r="10"></circle>
        <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
        <line stroke="#9169ed" x1="9" y1="9" x2="9.01" y2="9"></line>
        <line stroke="#9169ed" x1="15" y1="9" x2="15.01" y2="9"></line>
      </svg>
      <p className={titleClasses}>Discover Personal Insights</p>
      <p className={textClasses}>
        In today's fast-paced world, it's easy to lose sight of your own goals and values. Daily journaling provides a
        space for reflection so you can better understand your own thoughts and feelings.
      </p>
    </div>
  );
  const growth = () => (
    <div key="growth" className="landingpage__triplet-box--3 landingpage__triplet-box">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="url(#g1)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`feather feather-zap ${iconClasses}`}
      >
        <defs>
          <linearGradient id="g1" x1=".5" x2=".5" y2="1">
            <stop offset=".143" stopColor="#9169ed" />
            <stop offset="1.714" stopColor="#ff5b5b" />
          </linearGradient>
        </defs>
        <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
      </svg>
      <p className={titleClasses}>Achieve Growth</p>
      <p className={textClasses}>
        Journaling improves self-awareness, problem-solving, creativity, and emotional regulation.
      </p>
    </div>
  );

  return (
    <div key="philosophyTriplet" className="landingpage__triplet-container landingpage__box">
      {clarity()}
      {insight()}
      {growth()}
    </div>
  );
};

const quotes = [
  { text: "Journaling is like whispering to one's self and listening at the same time.", author: 'Mina Murray' },
  {
    text: 'Writing in a journal reminds you of your goals and of your learning in life. It offers a place where you can hold a deliberate, thoughtful conversation with yourself.',
    author: 'Robin Sharma'
  },
  {
    text: 'Journaling is a powerful tool for self-discovery, self-expression, and problem-solving',
    author: 'Mari L. McCarthy'
  },
  { text: 'The act of writing is the act of discovering what you believe', author: 'David Hare' },
  {
    text: "Keeping a journal of what's going on in your life is a good way to help you distill what's important and what's not",
    author: 'Martina Navratilova'
  },
  {
    text: "Journaling is not just about writing down your thoughts; it's about discovering the depths of your mind, finding clarity in chaos, and unlocking the potential within you",
    author: 'ChatGPT'
  },
  {
    text: "A good writer doesn't just think, and then write down what they thought, as a sort of transcript. A good writer will almost always discover new things in the process of writing. And there is, as far as I know, no substitute for this kind of discovery.",
    author: 'Paul Graham'
  }
];
const intro = () => {
  const quote = quotes[Math.floor(Math.random() * quotes.length)];
  return (
    <div className="landingpage__intro landingpage__box">
      <div className="text--caption text--bold text--gradient-color landingpage-intro__title">BECOME YOUR OWN GURU</div>
      <div className="text--heading-4 text--regular-weight text--primary-100">
        {`"${quote.text}"`}
        <div>{`- ${quote.author}`}</div>
      </div>
    </div>
  );
};

const featureDemoGrid = () => {
  const calendarDemo = () => {
    const calendarText = () => (
      <div key="calendar-demo" className="landingpage__demo-text-container">
        <p className="text--caption text--bold text--gradient-color">CALENDAR</p>
        <p className="text--heading-2 text--bold text--primary-800">Track Your Progress 🚀</p>
        <p className="text--paragraph-2 text--regular text--primary-900">
          Stay motivated and track progress with ease using our app. See your past entries and improve consistency to
          achieve your potential.
        </p>
      </div>
    );

    const calendarImage = () => (
      <div key="calendar-image" className="landingpage__demo-image landingpage__box-column">
        <img alt="calendar" src={require('./resources/calendar.png')}></img>
      </div>
    );
    return [calendarText(), calendarImage()];
  };

  const entryDemo = () => {
    const entryImage = () => (
      <div key="entry-image" className="landingpage__demo-image landingpage__box-column">
        <img alt="single-entry-view" src={require('./resources/insights.png')}></img>
      </div>
    );

    const entryText = () => (
      <div key="entry-text" className="landingpage__demo-text-container">
        <p className="text--caption text--bold text--gradient-color">OFFLINE JOURNALING</p>
        <p className="text--heading-2 text--bold text--primary-800">(✨New) Journal with Pen and Paper ✍</p>
        <p className="text--paragraph-2 text--regular text--primary-900">
          Journal the old fashion way, by hand with your favorite pen and notebook. Send a picture of your entry to your
          private Jumble email address and we'll do the rest. No need to type it out.
        </p>
      </div>
    );
    return [entryText(), entryImage()];
  };
  return (
    <div key="demo-grid" className="landingpage__demo-grid-container">
      {calendarDemo()}
      {entryDemo()}
    </div>
  );
};

const howItWorks = () => {
  const howItWorksSection = ({
    containerClassName,
    img,
    title,
    text
  }: {
    containerClassName: string;
    img: any;
    title: string;
    text: string;
  }) => {
    return (
      <div key={title} className={`landingpage__howitworks-section ${containerClassName}`}>
        <div className="landingpage__howitworks-img-container">
          <img alt="abstract typewriter cartoon" className="landingpage__howitworks-image-sizing" src={img}></img>
        </div>
        <div className="landingpage__howitworks-section-title text--semi-bold text--subheading text--primary-800">
          {title}
        </div>
        <div className="text--regular-weight text--paragraph-2 text--primary-900">{text}</div>
      </div>
    );
  };

  return [
    <h2
      key="how-it-works-title"
      className="landingpage__howitworks-title text--caption text--bold text--gradient-color"
    >
      HOW IT WORKS
    </h2>,
    <div key="how-it-works-container" className="landingpage__howitworks-container">
      {[
        howItWorksSection({
          containerClassName: 'landingpage__howitworks-section-1',
          img: habitGuyColor,
          title: 'Habit Formation',
          text: 'Build a daily journaling habit and stay consistent with reminders, a progress tracker, and streaks.'
        }),
        howItWorksSection({
          containerClassName: '',
          img: jumbleManHello,
          title: 'Chat with your Journal',
          text: 'Chat directly your journal. Our journal chat helps you gain clarity, discover personal insights, and achieve growth.'
        }),
        howItWorksSection({
          containerClassName: 'landingpage__howitworks-section-3',
          img: retroGuyColor,
          title: 'Retrospective Tooling',
          text: 'Jumble surfaces deep insights and trends from your journal entries. Use our journal exploration to gain a deeper understanding of your thoughts and feelings.'
        })
      ]}
    </div>
  ];
};

const Philosophy = () => (
  <div className="landingpage__philosophy-text landingpage__box text--regular-weight landingpage__box-column">
    <div className="text--heading-3-small text--primary-100">
      Our mission is to be a driving force in the mental health revolution.
    </div>
  </div>
);

export const Cta = () => {
  return (
    <div className="landingpage__cta-container landingpage__box" style={{ marginTop: '12%', marginBottom: '12%' }}>
      <div className="landingpage__cta-container-text">
        <div className="landingpage__cta-title text--caption text--bold text--gradient-color">CREATE ACCOUNT</div>
        <div className="landingpage__cta-text text--bold text--heading-1 text--primary-900">
          There's no better time to start journaling than now.
        </div>
        <CtaButton />
      </div>
      <Clock className="landingpage__cta-overlap-image" />
    </div>
  );
};

const Beliefs = () => (
  <div className="landingpage__six-box--container">
    <div className="landingpage__six-box landingpage__six-box--1">
      <div className="text--heading-4 landingpage__six-box--title">Consistency</div>
      <div className="text--paragraph-1">daily journaling is crucial to compounding benefits</div>
    </div>
    <div className="landingpage__six-box landingpage__six-box--2">
      <div className="text--heading-4 landingpage__six-box--title">Flow</div>
      <div className="text--paragraph-1">
        steady, continuous, unfiltered writing is conducive to flow of consciousness writin
      </div>
    </div>
    <div className="landingpage__six-box landingpage__six-box--3">
      <div className="text--heading-4 landingpage__six-box--title">Depth</div>
      <div className="text--paragraph-1">
        each entry should be about 2 pages or 500 words of text. This is enough time to get into a flow state and begin
        confronting unresolved issues
      </div>
    </div>
    <div className="landingpage__six-box landingpage__six-box--4">
      <div className="text--heading-4 landingpage__six-box--title">Privacy</div>
      <div className="text--paragraph-1">best in class security allows the user to write without filter</div>
    </div>
    <div className="landingpage__six-box landingpage__six-box--5">
      <div className="text--heading-4 landingpage__six-box--title">Simplicity</div>
      <div className="text--paragraph-1">
        the interface is simple and straightforward, there are no distractions from the task of journaling
      </div>
    </div>
    <div className="landingpage__six-box landingpage__six-box--6">
      <div className="text--heading-4 landingpage__six-box--title">Availability</div>
      <div className="text--paragraph-1">
        mobile phone journaling applications are tedious to use, Jumble is provided as a web application for ease of use
      </div>
    </div>
  </div>
);

export const Footer = () => (
  <div className="footer">
    <div className="landing-page__footer-container">
      <div className="text--paragraph-3 text--semi-bold text--neutral-500">
        {portugalSvg()}Crafted in{' '}
        <a
          className="text--paragraph-2 text--semi-bold"
          href="https://startupt.co/archive/150424.html??utm_source=jumblejournal&utm_medium=landingpage"
          target="_blank"
        >
          {' '}
          Portugal
        </a>
      </div>
      <div className="vertical-bar"></div> {/* Add vertical bar here */}
      <div className="text--paragraph-3 text--semi-bold text--neutral-500">
        {surferSvg()}Artwork by{'  '}
        <a className="text--paragraph-2 text--semi-bold" href="https://kelleybontemps.com/" target="_blank">
          {' '}
          Kelley Bontemps
        </a>
      </div>
    </div>
    <LegalFooter />
  </div>
);

const LandingPage = () => {
  return (
    <div className="landingpage__container">
      <Navigation />
      <div className="landingpage">
        <div className="landingpage__section">
          <Hero />
          <PhilosophyTriplet />
        </div>
        {/* {intro()} */}
        <div
          className="flex-column-centered flex-gap-32 w-100 margin-top-48"
          style={{ gridColumn: '1 / 13', padding: '32px' }}
        >
          <div className="text text--heading-2 text--bold text--primary-800">
            See what people are saying about Jumble Journal
          </div>
          <Carousel />
        </div>
        <div className="landingpage__section">
          {featureDemoGrid()}
          {howItWorks()}
        </div>
        <Cta />
        <Footer />
        {/* <h2 className="landingpage__philosophy-title landingpage__text text--heading-2-small text--bold">
          At Jumble we Believe
        </h2> */}
        {/* <Philosophy /> */}
        {/* <Beliefs /> */}
      </div>
    </div>
  );
};

const surferSvg = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="63"
    viewBox="0 0 960 630"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,630.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M5328 5555 c-19 -33 -22 -35 -38 -20 -9 8 -19 15 -23 15 -15 0 -37
-34 -37 -58 0 -15 -7 -36 -15 -46 -8 -11 -15 -30 -15 -43 0 -28 -16 -30 -29
-4 -6 10 -32 33 -59 50 -54 34 -113 41 -146 16 -18 -14 -38 -82 -40 -140 -1
-29 1 -30 39 -30 32 0 40 4 43 20 2 11 8 37 13 57 l10 37 44 -21 c37 -18 48
-30 66 -74 13 -34 20 -70 19 -101 -2 -26 -2 -56 -1 -65 1 -23 65 -35 86 -18
13 11 18 10 29 -5 9 -12 19 -15 34 -11 27 9 55 60 47 86 -7 23 13 35 73 44 39
6 42 5 42 -18 0 -31 44 -74 85 -82 92 -17 120 24 120 171 0 90 -2 104 -25 136
-29 43 -72 57 -105 35 -19 -13 -23 -11 -52 24 -32 37 -100 80 -129 80 -8 0
-24 -16 -36 -35z m48 -71 c12 5 31 -9 65 -46 49 -53 54 -65 43 -105 -6 -21
-11 -22 -63 -19 -33 3 -59 10 -62 18 -39 81 -48 186 -14 158 8 -7 22 -9 31 -6z"
      />
      <path
        d="M5567 4856 c-12 -5 -32 -8 -44 -7 -29 3 -38 -16 -13 -29 12 -7 27 -7
38 -2 22 12 144 34 161 30 7 -2 15 2 17 7 5 13 -129 13 -159 1z"
      />
      <path
        d="M5744 4859 c-5 -8 46 -14 91 -11 37 2 93 -20 103 -42 8 -15 11 -16
11 -5 1 8 -10 26 -24 39 -21 20 -36 24 -100 25 -42 1 -78 -2 -81 -6z"
      />
      <path
        d="M5416 4815 c-15 -8 -32 -13 -37 -10 -4 3 -16 1 -26 -4 -10 -5 -47
-17 -83 -27 -36 -10 -67 -21 -70 -24 -3 -3 -11 -5 -17 -6 -7 -1 -21 -2 -31 -2
-10 -1 -28 -11 -40 -22 -12 -12 -22 -16 -22 -9 0 8 -5 8 -16 -2 -9 -7 -23 -14
-32 -15 -36 -4 -81 -23 -122 -50 -24 -16 -50 -33 -59 -39 -57 -37 -120 -129
-122 -179 0 -15 -22 -35 -77 -71 -171 -110 -245 -189 -321 -340 -43 -86 -73
-236 -50 -250 6 -4 8 -18 5 -30 -3 -13 -2 -27 4 -30 5 -3 10 -15 10 -27 0 -11
6 -32 14 -46 8 -15 17 -34 20 -42 19 -46 176 -200 205 -200 5 0 40 -33 78 -74
96 -104 128 -118 281 -122 67 -2 130 -7 140 -12 9 -5 42 -15 72 -22 30 -7 60
-15 65 -19 6 -3 24 -7 40 -8 17 -1 64 -7 105 -13 41 -6 95 -14 120 -16 24 -3
61 -14 81 -25 36 -18 171 -61 254 -80 22 -6 61 -20 88 -31 l47 -22 -5 -50 c-3
-28 -8 -64 -12 -81 -15 -71 -1 -330 21 -374 7 -14 16 -53 19 -86 4 -33 16 -85
28 -115 12 -30 21 -67 20 -82 -1 -15 3 -29 8 -32 5 -3 7 -15 4 -26 -3 -11 0
-26 6 -34 7 -8 11 -20 10 -28 -5 -24 11 -86 26 -98 8 -7 15 -22 15 -34 0 -11
14 -48 30 -80 17 -32 30 -64 30 -71 0 -6 9 -21 20 -33 11 -11 28 -35 37 -52
17 -30 32 -41 96 -71 l27 -12 -22 -25 c-32 -33 -224 -172 -239 -172 -4 0 -28
21 -53 46 -46 45 -47 47 -40 92 3 26 10 52 14 57 13 18 30 160 22 190 -13 47
-30 41 -37 -12 -3 -26 -12 -61 -20 -78 -15 -31 -39 -109 -49 -157 -6 -26 -10
-28 -59 -28 -95 1 -197 -68 -197 -132 0 -36 79 -147 105 -148 15 -1 35 -14 35
-23 0 -6 -3 -7 -7 -4 -3 4 -16 1 -27 -6 -12 -8 -42 -20 -67 -27 -63 -18 -87
-36 -71 -52 7 -7 12 -8 12 -2 0 5 19 9 42 7 25 -1 60 6 86 17 25 11 51 20 58
20 7 0 21 11 30 26 9 14 23 23 30 20 15 -6 126 54 164 88 14 12 36 27 50 33
14 6 45 26 70 44 73 54 110 79 119 79 15 0 100 81 97 93 -1 7 -1 9 1 5 3 -5
14 -3 26 4 32 17 163 131 242 212 110 110 248 229 305 260 19 11 59 38 89 60
29 23 65 49 80 58 14 9 31 21 36 25 36 31 250 143 271 143 3 0 30 11 59 25 30
13 67 27 82 31 16 4 33 10 38 14 14 9 84 29 126 34 18 3 58 10 88 16 29 5 91
10 136 10 l82 0 7 -37 c12 -77 7 -127 -21 -182 -16 -31 -32 -60 -36 -66 -72
-96 -180 -222 -309 -359 -36 -39 -81 -89 -100 -111 -18 -22 -53 -59 -77 -82
-33 -33 -42 -49 -39 -67 5 -23 2 -25 -66 -32 -39 -4 -81 -6 -93 -5 -13 1 -28
-1 -35 -6 -7 -4 -31 -9 -53 -10 -56 -2 -123 -11 -133 -17 -5 -3 -38 -8 -73
-11 -104 -10 -213 -35 -228 -53 -8 -11 -15 -13 -18 -6 -3 8 -16 9 -46 3 -52
-12 -201 -70 -205 -81 -2 -4 -8 -8 -14 -8 -19 0 -198 -91 -198 -100 0 -6 -6
-10 -14 -10 -8 0 -17 -7 -20 -16 -3 -9 -15 -14 -28 -12 -17 2 -23 -2 -22 -14
2 -21 -12 -24 -19 -5 -3 8 -21 3 -63 -18 -32 -16 -61 -32 -64 -35 -3 -3 -32
-18 -65 -35 -33 -16 -62 -32 -65 -36 -3 -3 -16 -9 -30 -12 -41 -11 -71 -40
-60 -57 7 -12 12 -12 22 -2 6 6 22 12 34 12 12 0 27 7 34 15 7 8 30 20 52 25
21 6 42 19 46 28 4 9 20 18 35 19 31 2 112 37 138 60 9 8 29 15 43 17 15 2 32
8 38 14 7 7 18 12 25 12 7 0 13 7 13 16 0 12 3 14 13 6 9 -8 19 -5 37 8 14 11
29 20 33 20 13 0 101 41 117 55 8 7 36 16 62 19 26 4 50 11 53 16 3 5 24 14
45 20 22 6 40 14 40 18 0 4 23 8 52 9 28 1 74 8 102 14 28 7 55 12 60 11 8 -3
83 7 221 28 33 6 110 12 170 15 61 2 144 8 185 11 41 4 122 6 180 5 58 -1 151
-1 207 -1 56 -1 105 -5 108 -10 4 -6 21 -10 38 -10 85 0 304 -37 331 -56 11
-8 33 -14 48 -14 16 0 36 -4 46 -10 52 -28 309 -59 502 -59 350 -1 485 33 449
113 -15 31 -49 42 -87 28 -15 -6 -47 -14 -72 -17 -25 -3 -61 -8 -80 -11 -19
-3 -118 -7 -220 -8 -156 -3 -207 0 -330 20 -80 13 -154 25 -165 28 -11 2 -40
9 -65 15 -258 60 -277 62 -667 78 -90 3 -163 9 -163 13 0 4 15 22 33 41 63 68
97 109 97 118 0 5 17 24 37 41 20 18 75 78 121 134 47 56 97 115 112 131 28
30 68 99 122 208 26 52 28 65 22 115 -7 58 -23 112 -52 169 -24 48 -48 50
-257 19 -126 -18 -243 -45 -250 -57 -4 -5 -14 -9 -23 -9 -9 0 -39 -11 -67 -25
-27 -13 -59 -25 -71 -25 -25 0 -206 -92 -254 -128 -19 -15 -44 -34 -56 -42
-12 -8 -43 -30 -69 -47 -25 -18 -50 -33 -56 -33 -5 0 -9 -4 -9 -8 0 -5 -17
-18 -37 -29 -52 -29 -151 -110 -218 -177 -53 -54 -73 -65 -89 -48 -4 4 -11 7
-14 8 -4 0 -26 6 -49 12 -43 13 -52 11 -137 -27 -42 -20 -126 -106 -126 -130
0 -26 -18 -7 -39 41 -13 29 -36 81 -52 116 -16 34 -29 71 -29 81 0 10 -7 24
-15 31 -8 7 -15 25 -15 41 0 38 -18 112 -42 176 -11 29 -17 57 -13 63 4 6 2
16 -3 23 -5 7 -12 26 -15 42 -3 17 -13 62 -23 100 -9 39 -18 111 -21 162 l-4
91 28 6 c80 15 126 19 211 20 l93 1 -7 -75 c-3 -41 -11 -97 -16 -125 -5 -27
-11 -63 -12 -80 -1 -16 -8 -43 -15 -60 -7 -16 -13 -45 -14 -62 -1 -18 -7 -35
-14 -37 -18 -7 -26 -81 -12 -104 16 -25 45 -34 57 -18 11 15 32 88 32 113 0
11 7 26 15 33 8 7 15 27 15 44 0 17 7 63 15 101 8 39 15 96 15 127 0 31 5 60
10 63 6 3 10 17 10 30 0 13 5 27 10 30 15 9 12 41 -6 56 -33 27 -323 22 -400
-8 -14 -5 -15 3 -11 63 3 38 8 84 12 102 6 27 3 36 -19 57 -15 14 -48 34 -74
45 -26 12 -55 25 -64 30 -10 6 -31 10 -46 10 -16 0 -51 7 -78 15 -27 8 -59 15
-72 15 -19 0 -21 4 -16 24 3 15 -1 34 -10 49 -15 22 -24 25 -127 31 -61 4
-117 10 -126 13 -12 5 -14 17 -10 57 12 110 9 104 92 190 40 42 65 103 65 157
0 52 0 53 71 117 38 36 77 79 86 96 9 17 28 49 42 71 14 22 30 54 36 70 16 43
18 167 4 201 -7 16 -11 38 -10 49 2 20 6 31 35 98 9 21 16 45 16 55 0 9 9 46
20 81 12 36 18 71 16 78 -4 8 5 18 19 23 14 5 25 13 25 18 0 18 117 90 165
102 89 22 108 18 172 -37 64 -56 108 -78 168 -87 22 -3 65 -11 95 -17 30 -6
71 -12 90 -14 19 -2 67 -10 105 -19 39 -9 95 -21 125 -26 30 -6 100 -19 155
-29 116 -22 302 -20 440 4 17 3 39 6 50 6 10 0 24 4 30 9 6 5 20 9 30 8 24 -1
150 27 188 41 26 10 31 20 28 49 -1 6 6 12 16 12 10 0 55 22 100 49 l82 49 11
-34 c7 -19 15 -34 19 -34 4 0 13 -12 20 -27 17 -37 0 -44 -103 -48 -53 -1 -95
-8 -116 -18 -18 -10 -39 -17 -46 -17 -23 0 -103 -43 -109 -58 -7 -19 33 -62
58 -62 10 0 33 10 50 22 98 70 331 83 388 22 17 -18 26 -21 40 -13 33 17 26
33 -74 149 -51 60 -88 122 -82 140 3 11 -3 28 -17 44 -21 25 -24 26 -43 11
-11 -8 -40 -31 -65 -50 -25 -19 -48 -35 -52 -35 -4 0 -28 -14 -53 -31 -28 -19
-54 -29 -66 -26 -17 5 -53 -3 -209 -43 -37 -10 -331 -49 -365 -49 -35 0 -268
30 -315 40 -111 25 -202 39 -290 44 -122 8 -182 27 -251 80 -81 62 -93 66
-146 60 -91 -12 -147 -34 -203 -80 l-55 -45 -3 47 c-3 38 -10 55 -35 81 -30
32 -35 33 -81 27 -58 -7 -195 -70 -281 -128 -33 -22 -62 -43 -65 -46 -19 -22
-83 -40 -150 -41 -41 -1 -86 -3 -100 -5 -51 -9 -85 -17 -100 -22 -8 -3 -23 -5
-32 -4 -10 1 -32 -4 -50 -12 -18 -8 -47 -21 -65 -28 -17 -7 -30 -16 -28 -19 2
-4 -9 -7 -25 -7 -36 0 -36 2 -10 50 11 21 18 42 15 47 -4 6 1 10 9 10 9 0 16
6 16 13 0 8 4 7 10 -3 9 -13 12 -13 24 3 7 9 18 17 25 17 6 0 11 5 11 10 0 6
10 14 23 19 12 5 49 21 82 35 87 39 94 42 160 62 33 10 66 22 73 28 7 5 15 6
18 3 4 -3 23 2 43 12 20 10 56 21 81 25 53 8 75 20 66 35 -10 15 -25 14 -60
-4z m338 -179 c6 -29 3 -60 -13 -130 -19 -76 -46 -158 -58 -175 -1 -2 -28 22
-59 53 -36 37 -81 69 -126 90 l-70 33 51 36 c61 44 241 139 255 134 6 -2 15
-21 20 -41z m-564 -166 c13 -9 11 -13 -12 -30 -14 -11 -48 -42 -75 -68 -27
-26 -74 -71 -105 -100 l-56 -52 -43 32 c-37 28 -76 78 -78 100 -1 3 25 21 57
39 61 36 160 68 231 74 23 2 46 6 49 9 8 8 15 7 32 -4z m230 -27 c118 -44 230
-141 230 -197 0 -33 -25 -113 -41 -132 -5 -6 -6 -14 -2 -17 3 -4 -6 -25 -21
-47 -14 -22 -26 -43 -26 -47 0 -29 -76 -183 -90 -183 -5 0 -32 28 -59 63 -57
71 -85 98 -120 117 -14 7 -34 21 -44 31 -11 10 -34 25 -53 34 -18 8 -34 19
-36 23 -4 9 -77 56 -100 65 -18 6 -25 37 -9 37 5 0 51 42 102 93 99 98 193
177 212 177 6 0 32 -8 57 -17z m-616 -141 c8 -16 37 -44 65 -63 28 -19 51 -37
51 -40 0 -3 -10 -17 -22 -31 -94 -105 -176 -230 -260 -396 l-63 -124 -3 -99
-4 -99 -43 30 c-61 42 -128 126 -143 179 -17 63 -26 198 -12 206 5 3 10 17 10
31 0 13 4 32 9 42 5 9 20 39 32 66 34 69 157 197 247 256 43 27 82 54 87 60
18 16 34 11 49 -18z m241 -182 c6 -9 191 -136 226 -155 11 -5 19 -13 19 -18 0
-4 19 -27 43 -50 62 -63 107 -123 107 -146 0 -60 -75 -120 -225 -179 -44 -17
-93 -37 -110 -43 -16 -7 -40 -14 -52 -15 -12 -2 -31 -8 -42 -14 -24 -12 -80
-11 -98 3 -9 7 -13 5 -13 -6 0 -26 64 -57 107 -51 80 12 211 48 272 75 35 16
66 27 68 25 2 -2 -1 -25 -8 -50 -11 -43 -62 -116 -80 -116 -5 0 -27 -13 -51
-29 -24 -16 -68 -37 -98 -46 -30 -10 -64 -22 -75 -26 -37 -15 -70 -14 -108 4
-21 10 -55 21 -75 24 -39 7 -102 34 -166 73 l-38 23 4 101 c3 83 8 109 26 139
12 21 22 47 22 57 0 10 5 22 10 25 6 4 23 32 39 63 16 31 45 79 65 107 20 27
36 55 36 62 0 6 3 13 8 15 9 4 83 94 106 131 19 27 68 38 81 17z m650 -92 c-5
-25 -19 -57 -36 -76 -35 -40 -36 -36 -4 34 14 30 25 61 25 69 0 8 5 15 11 15
7 0 8 -14 4 -42z m-419 -752 c-3 -14 -2 -28 4 -31 5 -3 10 -15 10 -25 0 -18
-5 -19 -47 -13 -27 3 -59 9 -73 13 -24 6 -23 8 20 30 25 12 49 29 53 36 4 8
15 14 24 14 11 0 14 -6 9 -24z"
      />
      <path
        d="M4898 4747 c-16 -6 -28 -14 -28 -19 0 -4 -5 -8 -12 -8 -6 0 -19 -6
-27 -14 -9 -7 -41 -27 -71 -43 -96 -52 -130 -75 -130 -85 0 -5 -6 -8 -12 -5
-7 2 -22 -3 -33 -13 -11 -9 -69 -45 -130 -79 -127 -73 -142 -85 -192 -160 -21
-31 -53 -72 -73 -91 -57 -58 -120 -132 -120 -141 0 -5 -5 -9 -12 -9 -7 0 -32
-21 -57 -48 -26 -26 -62 -59 -81 -74 -35 -26 -140 -128 -195 -190 -42 -47
-182 -171 -209 -185 -33 -17 -86 -25 -100 -15 -6 5 -40 12 -76 16 -36 4 -82
12 -102 18 -45 13 -68 -1 -68 -42 0 -24 -118 -271 -141 -295 -18 -18 -9 -50
18 -62 35 -17 48 -16 64 3 22 27 166 94 232 108 34 7 70 17 80 21 21 9 21 6 1
-66 -16 -55 -15 -58 4 -77 48 -48 75 -49 142 -6 19 12 55 25 80 29 91 15 109
45 61 105 -17 21 -31 43 -31 47 0 10 -33 77 -47 96 -5 7 -15 25 -21 40 -12 27
-10 30 39 75 58 53 173 166 206 203 13 13 50 49 85 79 185 165 374 377 423
474 14 28 33 47 62 61 24 12 58 34 76 51 19 16 53 38 76 48 22 11 46 25 53 33
6 8 26 20 45 28 18 8 36 21 39 30 3 8 14 15 23 15 9 0 30 14 48 30 18 17 40
30 50 30 10 0 38 16 62 35 24 19 52 35 62 35 23 0 25 16 3 24 -20 8 -33 6 -66
-7z m-1452 -1238 c12 -10 7 -57 -8 -75 -4 -6 -30 -17 -56 -23 -101 -26 -128
-33 -164 -46 -21 -7 -38 -10 -38 -7 0 6 13 41 54 145 l11 27 95 -6 c52 -4 100
-11 106 -15z m145 -127 c3 -17 14 -38 23 -47 20 -20 21 -35 1 -35 -8 0 -32 -9
-52 -20 -20 -11 -38 -18 -39 -17 -5 4 22 132 30 145 12 21 30 8 37 -26z"
      />
      <path
        d="M3136 1292 c-2 -2 -21 -5 -42 -8 -190 -21 -321 -47 -479 -94 -16 -5
-59 -17 -95 -28 -36 -10 -87 -28 -114 -40 -27 -12 -55 -22 -62 -22 -8 0 -14
-4 -14 -10 0 -5 -3 -9 -7 -8 -5 2 -100 -27 -213 -64 -236 -76 -441 -134 -520
-148 -30 -5 -71 -14 -90 -20 -34 -11 -153 -36 -215 -45 -16 -3 -37 -8 -45 -11
-25 -11 -313 -7 -386 5 -38 6 -78 12 -89 14 -11 1 -26 6 -32 10 -7 5 -13 6
-13 2 0 -3 -22 6 -50 20 -27 14 -54 25 -61 25 -6 0 -34 18 -61 40 -39 30 -59
40 -88 40 -20 0 -40 -3 -43 -6 -10 -11 12 -44 26 -38 7 2 28 -9 46 -26 37 -36
157 -115 198 -131 121 -47 384 -75 551 -58 53 5 104 12 112 15 8 4 33 8 55 11
22 2 63 11 90 19 28 8 84 21 125 28 83 15 193 43 315 80 44 14 119 36 167 51
48 14 90 30 93 36 4 5 21 9 40 9 19 0 37 5 40 10 3 6 13 10 22 10 9 0 34 8 57
19 53 24 90 37 204 71 51 16 117 38 145 49 29 12 81 26 117 31 36 6 90 15 120
20 204 34 253 36 640 35 258 -1 409 -5 435 -13 116 -32 162 -41 225 -43 24 -1
51 -5 60 -9 8 -5 44 -15 80 -24 95 -24 498 -19 523 6 15 15 15 19 1 47 -8 17
-20 34 -26 37 -6 4 -113 8 -237 9 -146 1 -236 6 -254 13 -16 7 -50 12 -76 12
-26 0 -70 6 -97 14 -27 8 -70 17 -96 21 -26 3 -50 10 -53 16 -4 5 -9 8 -13 5
-4 -2 -34 1 -67 6 -55 10 -841 19 -849 10z"
      />
    </g>
  </svg>
);

const portugalSvg = () => (
  <svg
    className="landingpage__footer-portugal-svg"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
  >
    <path
      style={{ fill: '#FF4B55' }}
      d="M400,0H185.379v512H400c61.856,0,112-50.144,112-112V112C512,50.144,461.856,0,400,0z"
    />
    <path
      style={{ fill: '#73AF00' }}
      d="M112,0C50.144,0,0,50.144,0,112v288c0,61.856,50.144,112,112,112h73.379V0H112z"
    />
    <circle style={{ fill: '#FFE15A' }} cx="185.45" cy="256" r="112.38" />
    <path
      style={{ fill: '#FF4B55' }}
      d="M222.941,218.508v49.99c0,20.673-16.819,37.492-37.492,37.492s-37.492-16.819-37.492-37.492v-49.99  L222.941,218.508 M235.438,193.513h-99.98c-6.902,0-12.497,5.595-12.497,12.497v62.487c0,34.511,27.977,62.487,62.487,62.487  s62.487-27.977,62.487-62.487V206.01C247.936,199.108,242.34,193.513,235.438,193.513L235.438,193.513z"
    />
    <path
      style={{ fill: '#F5F5F5' }}
      d="M211.931,229.517v35.31c0,14.603-11.88,26.483-26.483,26.483s-26.483-11.88-26.483-26.483v-35.31  H211.931"
    />
    <g>
      <circle style={{ fill: '#FFE15A' }} cx="135.36" cy="206.01" r="6.249" />
      <circle style={{ fill: '#FFE15A' }} cx="235.34" cy="206.01" r="6.249" />
      <circle style={{ fill: '#FFE15A' }} cx="135.36" cy="256" r="6.249" />
      <circle style={{ fill: '#FFE15A' }} cx="235.34" cy="256" r="6.249" />
      <circle style={{ fill: '#FFE15A' }} cx="185.35" cy="206.01" r="6.249" />
      <circle style={{ fill: '#FFE15A' }} cx="222.87" cy="302.08" r="6.249" />
      <circle style={{ fill: '#FFE15A' }} cx="148.57" cy="302.08" r="6.249" />
    </g>
    <g>
      <path
        style={{ fill: '#41479B' }}
        d="M193.52,252.832v10.762c0,4.451-3.621,8.071-8.071,8.071s-8.071-3.621-8.071-8.071v-10.762H193.52"
      />
      <path
        style={{ fill: '#41479B' }}
        d="M193.52,227.317v10.762c0,4.451-3.621,8.071-8.071,8.071s-8.071-3.621-8.071-8.071v-10.762H193.52"
      />
      <path
        style={{ fill: '#41479B' }}
        d="M193.52,278.608v10.762c0,4.451-3.621,8.071-8.071,8.071s-8.071-3.621-8.071-8.071v-10.762H193.52"
      />
      <path
        style={{ fill: '#41479B' }}
        d="M215.26,252.832v10.762c0,4.451-3.621,8.071-8.071,8.071s-8.071-3.621-8.071-8.071v-10.762H215.26"
      />
      <path
        style={{ fill: '#41479B' }}
        d="M171.779,252.832v10.762c0,4.451-3.621,8.071-8.071,8.071s-8.071-3.621-8.071-8.071v-10.762H171.779   "
      />
    </g>
  </svg>
);

export default LandingPage;
