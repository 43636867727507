import { EntryBlock } from '../../components/calendar/types';

export const cleanEntryState = (newEntryState: EntryBlock[]) => {
  const result = newEntryState.reduce((acc, curr) => {
    const lastObj = acc[acc.length - 1];
    if (lastObj && lastObj.role === curr.role) {
      return [
        ...acc.slice(0, -1),
        {
          role: lastObj.role,
          content: `${lastObj.content}\n${curr.content}`
        }
      ];
    }
    return [...acc, curr];
  }, [] as EntryBlock[]);
  return result;
};

export const getWordCount = (entryState: EntryBlock[] | null) => {
  if (!entryState) {
    return 0;
  }
  return entryState.reduce((sum, section) => {
    const sectionWordCount = section.role === 'user' ? section.content.split(/\s+/).filter((v) => v !== '').length : 0;
    return sum + sectionWordCount;
  }, 0);
};
