import React, { useState } from 'react';
import '@fontsource/inter';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import 'react-datepicker/dist/react-datepicker.css';
import '../../css/App.css';
import '../../css/Font.css';
import { checkMentor } from '../../api';

const mentorSelectorTooltip = (
  <Tooltip id="mentor-selector-tooltip">
    When you press 'Enter' while journaling, I'll jump in and give my two cents. You can turn this off within the
    meatball menu in the upper right.
  </Tooltip>
);

const MentorSelector = (props: {
  suggestedMentors: string[];
  setSuggestedMentors: React.Dispatch<React.SetStateAction<string[]>>;
  mentor: string;
  setMentor: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { setMentor, mentor, setSuggestedMentors, suggestedMentors } = props;
  const [filterValue, setFilterValue] = useState('');
  const [checkingMentor, setCheckingMentor] = useState(false);

  const setNewMentor = async () => {
    if (!filterValue || filterValue.length > 54) {
      window.alert('Please choose a shorter name.');
      return;
    }
    setCheckingMentor(true);
    const response = await checkMentor({ name: filterValue });
    const { canImpersonate } = response.data;
    if (canImpersonate) {
      setMentor(filterValue);

      localStorage.setItem('lastMentor', filterValue);
      setSuggestedMentors([...suggestedMentors, filterValue]);
      localStorage.setItem('savedMentors', JSON.stringify([...suggestedMentors, filterValue]));
      setCheckingMentor(false);
      setFilterValue('');
      return;
    }
    setCheckingMentor(false);
    setFilterValue('');
    window.alert(`Sorry, we don't recognize ${filterValue}. Check the spelling and try again.`);

    return;
  };

  const CustomDropdownMenu = React.forwardRef<any, any>(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      return (
        <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Choose a mentor"
            onChange={(e) => {
              setFilterValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setNewMentor();
              }
            }}
            value={filterValue}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter((child: any) => {
              if (!filterValue) {
                return true;
              }
              const childValue = child?.props?.children;
              if (!childValue) {
                return false;
              }
              if (childValue?.toLowerCase() === 'create new mentor') {
                return true;
              }

              return childValue.toLowerCase()?.startsWith(filterValue?.toLowerCase());
            })}
          </ul>
        </div>
      );
    }
  );

  const CustomToggle = React.forwardRef<any, any>(({ children, onClick }, ref) => (
    <button
      className="mentor__nav--filter text text--regular-weight text--paragraph-2"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </button>
  ));

  return (
    <div style={{ marginLeft: '1em' }}>
      <Dropdown
        as={ButtonGroup}
        onSelect={async (eventKey) => {
          if (eventKey === 'create-new') {
            await setNewMentor();
          }
          const clickedTag: any = eventKey;
          if (!clickedTag) {
            return;
          }
          setMentor(clickedTag);
        }}
      >
        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
          <div className="flex-row-space-between padding-sides-8">
            <OverlayTrigger placement="right" overlay={mentorSelectorTooltip}>
              <div>{checkingMentor ? filterValue : mentor}</div>
            </OverlayTrigger>
            {checkingMentor && (
              <span
                key="span"
                style={{ height: '1vw', width: '1vw', marginLeft: '1em' }}
                className="spinner-border"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            <FontAwesomeIcon icon={faAngleDown} style={{ marginLeft: '.6em' }} />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomDropdownMenu}>
          {filterValue && (
            <Dropdown.Item eventKey="create-new" key="create-new">
              Create New Mentor
            </Dropdown.Item>
          )}
          {suggestedMentors.map((mentorOption, index) => {
            return (
              <Dropdown.Item eventKey={mentorOption} key={index.toString()} active={mentor === mentorOption}>
                {mentorOption}
              </Dropdown.Item>
            );
          })}
          <Dropdown.Divider />
          <Dropdown.Item eventKey="clear" key="clear">
            Turn off
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default MentorSelector;
