import React from 'react';
import Navigation from '../../components/Nav/Nav';
import Accordion from 'react-bootstrap/Accordion';
import { Cta, Footer } from '../../LandingPage';
import { faqData } from './data';
import './faq.css';

const Faq = () => {
  return (
    <div className="faq-container">
      <Navigation />
      <div className="faq-wrapper">
        <div className="faq-text-wrapper">
          <h3 className="text text--caption text--bold text--gradient-color">FAQ'S</h3>
          <h2 className="text text--bold text--heading-1">Frequently Asked Questions</h2>
          <Accordian />
        </div>
      </div>
      <Cta />
      <Footer />
    </div>
  );
};

const Accordian = () => {
  return (
    <Accordion defaultActiveKey={[]} flush>
      {faqData.map((qa, i) => (
        <Accordion.Item key={i.toString()} eventKey={i.toString()}>
          <Accordion.Header>{qa.question}</Accordion.Header>
          <Accordion.Body className="text text--paragraph-2 text--regular-weight text--primary-900">
            {qa.answer}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default Faq;
