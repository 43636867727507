import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuthContext } from '../context';

const LogoutButton = (props: { label: string }) => {
  const { label } = props;
  const authContext = useAuthContext();
  const { signOut } = authContext;
  const history = useHistory();
  const location = useLocation();

  let loginClass = 'sidenav__log-out';
  if (location.pathname.includes('/entry/') || location.pathname === '/') {
    loginClass = 'topnav__log-out';
  }

  return (
    <button
      key="logout-button"
      className={`topnav__nav-item text text--semi-bold text--paragraph-2 ${loginClass}`}
      onClick={() => {
        if (signOut !== undefined) {
          console.log('signing out');
          sessionStorage.removeItem('stripeCustomerId');
          signOut();
        }
        history.push(`/`);
      }}
    >
      <FontAwesomeIcon style={{ marginRight: '.6em' }} icon={faArrowRightFromBracket} />
      {label}
    </button>
  );
};

export default LogoutButton;
