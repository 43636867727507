import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenNib } from '@fortawesome/free-solid-svg-icons';

const CreateNewEntryButton = (params: {
  navigateToDailyEntry: () => void;
  className: string;
  btnText: string;
  disabled: boolean;
}) => {
  const { navigateToDailyEntry, className, btnText, disabled } = params;
  const [isLoading, setIsLoading] = useState(false);
  return (
    <button
      disabled={disabled}
      className={className}
      onClick={() => {
        setIsLoading(true);
        navigateToDailyEntry();
      }}
    >
      {isLoading ? (
        <span
          style={{ marginRight: '8px' }}
          className="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        ></span>
      ) : (
        <FontAwesomeIcon icon={faPenNib} style={{ marginRight: '.6em' }} />
      )}
      {`\t${btnText}`}
    </button>
  );
};

export default CreateNewEntryButton;
