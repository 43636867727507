import { EntryAction, EntryActions } from './entries-actions';

export type EntryState = { entries: { name: string; imported: boolean }[] };

const addEntry = (state: EntryState, action: EntryAction) => {
  if (action.type !== EntryActions.ADD_ENTRY) {
    return state;
  }
  return { entries: sort([...state.entries, { name: action.payload.name, imported: action.payload.imported }]) };
};

const setEntries = (state: EntryState, action: EntryAction) => {
  if (action.type !== EntryActions.SET_ENTRIES) {
    return state;
  }
  return { entries: sort(action.payload.entries) };
};

const deleteEntry = (state: EntryState, action: EntryAction) => {
  if (action.type !== EntryActions.DELETE_ENTRY) {
    return state;
  }
  return {
    entries: sort([...state.entries.filter((entry) => entry.name !== action.payload.name)])
  };
};

const sort = (array: { name: string; imported: boolean }[]) => {
  return array.sort((a, b) => Number(a.name) - Number(b.name));
};

const entryReducer = (state: EntryState, action: EntryAction) => {
  switch (action.type) {
    case EntryActions.ADD_ENTRY:
      return addEntry(state, action);
    case EntryActions.SET_ENTRIES:
      return setEntries(state, action);
    case EntryActions.DELETE_ENTRY:
      return deleteEntry(state, action);
    default:
      return state;
  }
};

export default entryReducer;
