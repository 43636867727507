export enum EntryActions {
  ADD_ENTRY = 'ADD_ENTRY',
  SET_ENTRIES = 'SET_ENTRIES',
  DELETE_ENTRY = 'DELETE_ENTRY'
}

export type EntryAction =
  | { type: EntryActions.ADD_ENTRY; payload: { name: string; imported: boolean } }
  | {
      type: EntryActions.SET_ENTRIES;
      payload: { entries: { name: string; imported: boolean }[] };
    }
  | {
      type: EntryActions.DELETE_ENTRY;
      payload: { name: string };
    };
