import React, { useState, useEffect, KeyboardEvent, useRef } from 'react';
import { Api, queryGptJournalAssistant } from '../../api';
import { Colors, DEFAULT_ASSISTANT } from './types';
import { EntryBlock } from '../../components/calendar/types';

import '../../css/App.css';
import './singleEntry.css';
import { cleanEntryState } from './entryStateUtils';
import { useLocation } from 'react-router-dom';
import { useAuthContext, useGeneralContext } from '../../context';
import { userIsFree } from '../../util/subscription';

const AutoResizeTextBox = (props: {
  entryBackgroundColor: string;
  index: number;
  blocks: EntryBlock[];
  setEntryState: (entryState: EntryBlock[]) => void;
  setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
  journalAssistant: boolean;
  assistantThinking: null | number;
  setAssistantThinking: React.Dispatch<React.SetStateAction<null | number>>;
  textAreaFocusIndex: number | null;
  setTextAreaFocusIndex: React.Dispatch<React.SetStateAction<number | null>>;
  blur: boolean;
  setSelectedText: React.Dispatch<React.SetStateAction<string>>;
  mentor: string;
  entryIndex: number;
  setShowPremiumPaywall: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    blocks,
    entryBackgroundColor,
    setIsSaved,
    setEntryState,
    index,
    journalAssistant,
    assistantThinking,
    setAssistantThinking,
    textAreaFocusIndex,
    setTextAreaFocusIndex,
    blur,
    setSelectedText,
    mentor,
    entryIndex,
    setShowPremiumPaywall
  } = props;
  const entrySectionContent = blocks[index].content;
  const [value, setValue] = useState(entrySectionContent);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { subscriptionStatus } = useGeneralContext();

  const placeholders = ['what is on your mind...'];
  const [placeholder] = useState(placeholders[Math.floor(Math.random() * placeholders.length)]);
  const location = useLocation();
  const path = location.pathname;
  const authContext = useAuthContext();
  const { user } = authContext;

  const getTempDiv = (textAreaRef: React.RefObject<HTMLTextAreaElement>) => {
    const tempDiv = document.createElement('div');
    if (!textAreaRef.current) {
      return;
    }
    tempDiv.style.width = `${textAreaRef.current.clientWidth}px`;
    tempDiv.style.padding = window.getComputedStyle(textAreaRef.current).padding;

    const value = textAreaRef.current.value;
    tempDiv.innerText = value;
    return tempDiv;
  };

  useEffect(() => {
    if (textAreaRef.current) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      const tempDiv = getTempDiv(textAreaRef);
      if (!tempDiv) {
        return;
      }
      const textContainer = document.getElementsByClassName('entry-container');
      textContainer.item(0)?.appendChild(tempDiv);
      textAreaRef.current.style.height = '0px';
      const scrollHeight = textAreaRef.current.scrollHeight;
      if (textAreaFocusIndex === index) {
        textAreaRef.current.focus();
      }
      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.current.style.height = scrollHeight + 'px';
      textContainer.item(0)?.removeChild(tempDiv);
    }
  }, [textAreaRef, value, textAreaFocusIndex, entrySectionContent, index]);

  const initializeConversationCalled = useRef(false);

  useEffect(() => {
    const initializeConversation = async () => {
      if (conversationNotStarted) {
        try {
          // block input
          setAssistantThinking(entryIndex);
          // generate assistant advice
          const advice = await queryGptJournalAssistant({
            prompt: blocks,
            ...(mentor && mentor !== DEFAULT_ASSISTANT ? { impersonate: mentor } : {})
          });

          const newState = cleanEntryState([
            {
              role: 'assistant',
              content: `${mentor && !advice.data.gptResponse.startsWith(mentor) ? mentor + ': ' : ''}${
                advice.data.gptResponse
              }`
            },
            { role: 'user', content: '' }
          ]);
          // fill new textarea
          setEntryState(newState);
          // create new user text area below with cursor at the ready
          setAssistantThinking(null);
        } catch (error) {
          setAssistantThinking(null);
          console.error(error);
          window.alert('Error querying Jumble Bot Assistant');
        }
      }
    };
    const conversationNotStarted =
      path.includes('journal_with_') && blocks.filter((b) => b.role === 'assistant').length === 0 && !assistantThinking;
    if (
      conversationNotStarted &&
      !initializeConversationCalled.current &&
      Api.defaults.headers.common['Authorization']
    ) {
      initializeConversation();
      initializeConversationCalled.current = true;
    }
  }, [blocks, path, entryIndex, setAssistantThinking, assistantThinking, setEntryState, mentor, user]);

  const handleKeyDown = async (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      event.key === 'Enter' &&
      journalAssistant &&
      index === blocks.length - 1 &&
      blocks[index].content !== null &&
      blocks[index].content.trim().length > 0
    ) {
      const limitConversationForFreeUser =
        userIsFree(subscriptionStatus) &&
        path.includes('journal_with_') &&
        blocks.filter((b) => b.role === 'assistant').length >= 3;

      if (limitConversationForFreeUser) {
        setShowPremiumPaywall(true);
        return;
      }
      try {
        // block input
        setAssistantThinking(entryIndex);
        // generate assistant advice
        const advice = await queryGptJournalAssistant({
          prompt: blocks,
          ...(mentor && mentor !== DEFAULT_ASSISTANT ? { impersonate: mentor } : {})
        });

        const newState = cleanEntryState([
          ...blocks,
          {
            role: 'assistant',
            content: `${mentor && !advice.data.gptResponse.startsWith(mentor) ? mentor + ': ' : ''}${
              advice.data.gptResponse
            }`
          },
          { role: 'user', content: '' }
        ]);
        // fill new textarea
        setEntryState(newState);
        // create new user text area below with cursor at the ready
        setAssistantThinking(null);
        setTextAreaFocusIndex(newState.length - 1);
      } catch (error) {
        setAssistantThinking(null);
        console.error(error);
        window.alert('Error querying Jumble Bot Assistant');
      }
    }
  };

  const handleTextSelection = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLTextAreaElement;
    const { value, selectionStart, selectionEnd } = target;
    const selectedText = value.substring(selectionStart, selectionEnd);
    setSelectedText(selectedText);
  };

  return (
    <textarea
      onSelect={handleTextSelection}
      disabled={assistantThinking !== null}
      onKeyDown={handleKeyDown}
      key={`textarea-${index}`}
      onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
      // see https://testing-library.com/docs/queries/byrole/
      aria-label="Journal Entry Text"
      className={`single-entry__text-field text text--regular-weight text--body-large ${
        entryBackgroundColor === Colors.Dark ? 'text--neutral-50' : 'text--primary-900'
      } ${blur ? 'blur' : ''}`}
      ref={textAreaRef}
      rows={1}
      name="entry"
      placeholder={placeholder}
      value={blocks[index].content}
      onChange={(event) => {
        const val = event.target?.value;
        const newEntryState: EntryBlock[] = cleanEntryState([
          ...blocks.slice(0, index),
          { role: 'user', content: val },
          ...blocks.slice(index + 1, blocks.length)
        ]);
        setValue(val);
        setEntryState(newEntryState);
        setIsSaved(false);
      }}
    ></textarea>
  );
};

export default AutoResizeTextBox;
