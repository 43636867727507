import React, { useState } from 'react';
import './carousel.css';
import '../../css/App.css';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { items } from './data';
import { Item } from './types';
import { useGeneralContext } from '../../context';

const Carousel = () => {
  const [index, setIndex] = useState(0);
  let startX = 0;
  let endX = 0;

  const next = () => {
    if (index === items.length - 1) {
      setIndex(0);
      return;
    }
    setIndex(index + 1);
  };
  const previous = () => {
    if (index === 0) {
      setIndex(items.length - 1);
      return;
    }
    setIndex(index - 1);
  };

  const handleTouchStart = (e: any) => {
    startX = e.touches[0].clientX;
  };

  const handleTouchMove = (e: any) => {
    endX = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const diff = startX - endX;
    if (diff > 50) {
      next();
    } else if (diff < -50) {
      previous();
    }
  };

  return (
    <>
      <div
        className="carousel-container"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {items.map((item, i) => (
          <CarouselItem
            key={i}
            item={item}
            translatePercent={-index * 100 + (items.length - 1) * 50}
            hidden={index !== i}
          />
        ))}
      </div>
      <div className="w-100 flex-row-centered flex-gap-8 text text--bold text--paragraph-3 margin-top-16">
        <button className="carousel-nav-btn" onClick={previous}>
          <ChevronLeft size={36} />
        </button>
        {items.map((_, i) => (
          <button
            key={`dot-${i}`}
            className={`carousel-nav-guide ${i === index ? 'carousel-nav-guide--selected' : ''}`}
            onClick={() => setIndex(i)}
          ></button>
        ))}
        <button className="carousel-nav-btn" onClick={next}>
          <ChevronRight size={36} />
        </button>
      </div>
    </>
  );
};

const CarouselItem = (props: { item: Item; hidden: boolean; translatePercent: number }) => {
  const { title, data, date, icon, role, location, cta, centeredContent } = props.item;
  const { hidden, translatePercent } = props;

  const { setFeedbackModalOpen } = useGeneralContext();

  return (
    <div
      className="carousel-item-container"
      style={{
        transform: `translate(${translatePercent}%)`,
        ...(hidden ? { opacity: 0 } : {})
      }}
    >
      <div className="carousel-icon">{icon || <></>}</div>
      <div
        className={`carousel-item--text-container ${
          centeredContent ? 'carousel-item--text-container--centered' : 'carousel-item--text-container--start'
        }`}
      >
        <div className="carousel-title-container">
          <div className="text text--subheading text--semi-bold text--neutral-800">{title}</div>
          {role && <div className="text text--caption text--neutral-500">{role}</div>}
        </div>
        {cta && (
          <button
            className="jumble-button-primary side-nav__item--create-account carousel-cta-button text--white text text--paragraph-2 text--semi-bold"
            onClick={() => {
              setFeedbackModalOpen(true);
            }}
          >
            Share your Story!
          </button>
        )}
        <div className="text text--paragraph-2 text--neutral-800 carousel-feedback-text">{data}</div>
        {(location || date) && (
          <div className="flex-column-left flex-gap-2 margin-bottom-4">
            {location && <div className="text text--caption text--neutral-500">{location}</div>}
            <div className="text text--caption text--neutral-500">{date}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Carousel;
