import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ContextProvider, EntryStateProvider, AuthContext, useGeneralContext, useAuthContext } from './context';
import './css/App.css';
import SingleEntry from './views/entry/SingleEntry';
import LandingPage from './LandingPage';
import { PageNotFound } from './NotFound';
import Home from './views/home/Home';
import Settings from './views/settings/Settings';
import Faq from './views/faq/Faq';
import AboutUs from './views/about-us/AboutUs';
import Retrospective from './views/retrospective/Retrospective';
import ChatWithJournal from './views/exploration/ChatWithJournal';
import { Toaster } from 'react-hot-toast';
import FeedbackModal from './views/feedback-modal/FeedbackModal';
import ChoosePlan from './views/choose-plan/ChoosePlan';
import TermsAndPrivacy from './views/terms-and-privacy/TermsAndPrivacy';
import { Amplify, Auth } from 'aws-amplify';
import awsExports from './aws-exports';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import components from './amplify-customization';
import { Api, retrieveSubscription, getUserData } from './api';
import { COUNTRY_CURRENCY_MAP, isSupportedCountry } from './views/choose-plan/data';

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID
  },
  oauth: {
    domain: 'auth.jumblejournal.org', // Replace with your Cognito domain
    scope: ['email', 'profile', 'openid'],
    redirectSignIn:
      process.env.NODE_ENV === 'development' ? 'http://localhost:3000/home' : 'https://jumblejournal.org/home', // Replace with your redirect URL
    redirectSignOut: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://jumblejournal.org',
    responseType: 'code' // or 'token', depending on your use case
  }
});

// TODO updgrade to v6 https://reactrouter.com/en/main/upgrading/v5
function App() {
  return (
    <Router>
      <div className="app">
        <ContextProvider>
          <EntryStateProvider>
            <Toaster />
            <FeedbackModal />
            <ChoosePlan />
            <Switch>
              <Route path="/terms-and-privacy" component={TermsAndPrivacy} />
              <ProtectedRoute path="/settings" component={Settings} />
              <Route path="/faq" component={Faq} />
              <Route path="/about" component={AboutUs} />
              <ProtectedRoute path="/chat" component={ChatWithJournal} />
              <ProtectedRoute exact path="/entry/:entryDateTitle" component={SingleEntry} />
              <ProtectedRoute exact path="/entry" component={SingleEntry} />
              <ProtectedRoute exact path="/journal_with_:persona" component={SingleEntry} />
              <ProtectedRoute exact path="/retrospective" component={Retrospective} />
              <ProtectedRoute path="/home" component={Home} />
              <Route exact path="/" component={LandingPage} />
              <Route path="*" component={PageNotFound} />
            </Switch>
          </EntryStateProvider>
        </ContextProvider>
      </div>
    </Router>
  );
}

const ProtectedRoute = (props: { component: any; path: string; exact?: true }) => {
  const { component: Component, path } = props;
  const exact = props.exact || false;

  useEffect(() => {
    const refreshTokens = async () => {
      try {
        await Auth.currentSession(); // This will refresh the token if it's about to expire
      } catch (error) {
        // Handle any errors that occur during token refresh
        console.log('Error refreshing token:', error);
      }
    };

    const interval = setInterval(refreshTokens, 1000 * 60 * 10); // Refresh the token every 10 minutes

    return () => clearInterval(interval); // Clean up the interval when the component unmounts
  }, []);

  return (
    <Authenticator
      variation="modal"
      loginMechanisms={['email']}
      socialProviders={['google']}
      signUpAttributes={['nickname']}
      components={components}
    >
      {({ signOut, user }) => {
        return (
          <AuthContext.Provider value={{ signOut, user }}>
            <AppRoute exact={exact} path={path} component={Component} />
          </AuthContext.Provider>
        );
      }}
    </Authenticator>
  );
};

const AppRoute = (props: { component: any; path: string; exact: boolean }) => {
  const { component: Component, path } = props;
  const exact = props.exact || false;

  const context = useGeneralContext();
  const {
    subscriptionStatus,
    setSubscriptionStatus,
    setCurrency,
    setJwtIsSet,
    jwtIsSet,
    setEntryBackgroundColor,
    setNickname,
    setImportationEmail,
    setSubscribed
  } = context;
  const authContext = useAuthContext();
  const { user } = authContext;

  useEffect(() => {
    // retreive use settings
    const retrieveUserSettings = async () => {
      try {
        const { settings, importationEmail } = await getUserData();

        if (settings?.entryBackgroundColor) {
          setEntryBackgroundColor(settings?.entryBackgroundColor);
        }
        if (settings?.nickname) {
          setNickname(settings?.nickname);
        }
        if (settings.octopusUserId && settings.octopusStatus === 'SUBSCRIBED') {
          setSubscribed(true);
        }
        if (importationEmail) {
          setImportationEmail(importationEmail);
        }
      } catch (error) {}
    };
    if (!jwtIsSet) {
      return;
    }
    retrieveUserSettings();
  }, [setEntryBackgroundColor, setNickname, jwtIsSet, setImportationEmail, setSubscribed]);

  useEffect(() => {
    if (!user) {
      return;
    }
    user.getSession((error: any, session: any) => {
      if (error) {
        return;
      }
      const jwt: string = session.getIdToken().getJwtToken();
      if (!jwt) {
        return;
      }
      Api.defaults.headers.common['Authorization'] = jwt;
      setJwtIsSet(true);
    });
  }, [user, setJwtIsSet]);

  useEffect(() => {
    const getGeoLocation = async () => {
      try {
        // Fetch the GeoJS geolocation data
        const response = await fetch('https://get.geojs.io/v1/ip/geo.json');
        const data = await response.json();
        const countryCode = data && data.country_code;
        if (isSupportedCountry(countryCode)) {
          setCurrency(COUNTRY_CURRENCY_MAP[countryCode]);
        }
      } catch (error) {
        console.log('unable to determine user location');
      }
    };
    getGeoLocation();
  }, [setCurrency]);

  useEffect(() => {
    const setUserSubscriptionStatus = async () => {
      try {
        const userSub = await retrieveSubscription();
        if (userSub?.data?.stripeCustomerId) {
          sessionStorage.setItem('stripeCustomerId', userSub?.data?.stripeCustomerId);
        }
        setSubscriptionStatus(userSub?.data?.status || null);
      } catch (error) {
        console.error(error);
      }
    };
    if (!jwtIsSet) {
      return;
    }
    setUserSubscriptionStatus();
  }, [subscriptionStatus, user, setSubscriptionStatus, jwtIsSet]);

  return <Route exact={exact} path={path} component={Component} />;
};

export default App;
