import { useEffect, useState } from 'react';
import { Map } from 'react-feather';
import { Api } from '../../api';
import Navigation from '../../components/Nav/Nav';
import PremiumUpsellModal from '../../components/PremiumUpsellModal';
import { useGeneralContext } from '../../context';
import '../../css/App.css';
import { getDayMonthYearFromDate } from '../../util/dateTime';
import '../exploration/chatWithJournal.css';
import './retrospective.css';
import { Nugget, categoryKeys } from './types';
import ExtractedNuggets from './ExtractedNuggets';
import TreeMap from './TreeMap';

const Retrospective = () => {
  const { jwtIsSet } = useGeneralContext();
  // const [selectedTags, setSelectedTags] = useState<string[]>([]);
  // const [categoryIndex, setCategoryIndex] = useState(0);
  const [nuggets, setNuggets] = useState<Nugget[]>([]);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const retrieveNuggets = async () => {
      try {
        setLoading(true);
        const startStr = `20220101000000000`;
        const today = getDayMonthYearFromDate(new Date());
        const endStr = `${today.year}${(today.month + 1).toString().padStart(2, '0')}${(today.day + 1)
          .toString()
          .padStart(2, '0')}000000000`;
        const queryStr = `start=${startStr}&end=${endStr}`;
        const response = await Api.get(`/nuggets?${queryStr}`);
        if (!response || !response.data) {
          return;
        }
        const nuggets = response.data;
        // setSelectedTags(
        //   [...new Set(nuggets.map((nug: any) => nug.tags).flat())].sort((a: any, b: any) =>
        //     a.localeCompare(b)
        //   ) as string[]
        // );
        setLoading(false);
        setNuggets(nuggets);
      } catch (error) {
        setLoading(false);
        window.alert('Failed to retrieve insights');
      }
    };
    if (jwtIsSet) retrieveNuggets();
  }, [jwtIsSet]);

  return (
    <div className="home__container">
      <Navigation />
      <PremiumUpsellModal
        showModal={showPremiumModal}
        setShowModal={setShowPremiumModal}
        message={`Get access to your extracted ${categoryKeys
          .slice(1, categoryKeys.length - 1)
          .map((v) => `${v}s`)
          .join(', ')}, and ${categoryKeys[categoryKeys.length - 1]}s with a premium subscription`}
      />
      <div className="explore__container">
        <div className="explore__wrapper">
          <div className="text text--bold text--subheading explore__main-title">
            <Map className="side-nav__item-logo text--primary-800" />
            Explore your Journal
          </div>
          <ExtractedNuggets nuggets={nuggets} loading={loading} setShowPremiumModal={setShowPremiumModal} />
          <TreeMap nuggets={nuggets} loading={loading} setShowPremiumModal={setShowPremiumModal} />
        </div>
      </div>
    </div>
  );
};

export default Retrospective;
