import React, { useState } from 'react';
import './settings.css';
import '../../css/App.css';
import { SubscriptionPlan } from './types';
import { Unlock } from 'react-feather';
import { useGeneralContext } from '../../context';
import { createCheckoutSession, createCustomerPortal } from '../../api/stripe-api';

export function UpgradePremiumButton() {
  const context = useGeneralContext();
  const { setPlansModalOpen, subscriptionStatus } = context;

  const stripeCustomerId = sessionStorage.getItem('stripeCustomerId');

  const premiumSubscriptionStatus = subscriptionStatus;

  if (stripeCustomerId && premiumSubscriptionStatus === 'premium_monthly') {
    return (
      <>
        <ManageSubscription key="ManageSubscription" stripeCustomerId={stripeCustomerId} header="" />
        <UpgradeToPremiumButton
          key="UpgradeToPremiumButton"
          header=""
          buttons={[
            {
              text: 'Upgrade to Annual (save 25%)',
              planId: 'premium_yearly',
              isUpgrade: true,
              classNames: 'jumble-button-primary text text--paragraph-1 text--semi-bold text--white'
            }
          ]}
        />
      </>
    );
  }
  if (stripeCustomerId && premiumSubscriptionStatus === 'premium_yearly') {
    return <ManageSubscription stripeCustomerId={stripeCustomerId} header="" />;
  }
  if (premiumSubscriptionStatus === 'lifetime') {
    return <></>;
  }
  return (
    <button
      className="jumble-button-primary text text--white text--paragraph-1 text--semi-bold"
      onClick={() => setPlansModalOpen(true)}
    >
      <Unlock size={18} />
      <div>Unlock Premium Features</div>
    </button>
  );
}

export const UpgradeToPremiumButton = (props: {
  header: string;
  buttons: { text: string; planId: SubscriptionPlan; isUpgrade: boolean; classNames: string }[];
}) => {
  const context = useGeneralContext();
  const { currency } = context;
  const { header, buttons } = props;
  const [creatingPurchaseSession, setCreatingPurchaseSession] = useState<number | null>(null);
  return (
    <div>
      <div className="text text--bold text--paragraph-2 text--neutral-500">{header}</div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {buttons.map((button, index) => {
          return (
            <form key={button.planId} className="upgrade-premium-form">
              <button
                className={button.classNames}
                type="button"
                onClick={async () => {
                  setCreatingPurchaseSession(index);
                  try {
                    const response = await createCheckoutSession({
                      planId: button.planId,
                      isUpgrade: button.isUpgrade,
                      currency: currency.code
                    });
                    const redirectLocation = response?.data?.location;
                    if (!redirectLocation) {
                      throw new Error('failed to create strip customer portal');
                    }
                    window.location.assign(redirectLocation);
                  } catch (error) {
                    setCreatingPurchaseSession(null);
                    console.error(error);
                    alert('Failed to create stripe checkout sessions');
                  }
                }}
              >
                {creatingPurchaseSession === index && (
                  <span
                    style={{ marginRight: '1em', textAlign: 'center', height: '1em', width: '1em' }}
                    className="spinner-border"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                {button.text}
              </button>
            </form>
          );
        })}
      </div>
    </div>
  );
};

export const ManageSubscription = (props: { stripeCustomerId: string; header: string }) => {
  const { stripeCustomerId, header } = props;
  const [creatingCustomerPortal, setCreatingCustomerPortal] = useState(false);
  return (
    <div>
      <div className="text text--bold text--paragraph-2 text--neutral-500">{header}</div>
      <button
        className="jumble-button-secondary text text--paragraph-1 text--semi-bold text--primary-700"
        type="button"
        onClick={async () => {
          setCreatingCustomerPortal(true);
          try {
            const response = await createCustomerPortal({ stripeCustomerId });
            const redirectLocation = response?.data?.location;
            if (!redirectLocation) {
              throw new Error('failed to create strip customer portal');
            }
            window.location.assign(redirectLocation);
          } catch (error) {
            setCreatingCustomerPortal(false);
            console.error(error);
            alert('Failed to create stripe checkout sessions');
          }
        }}
      >
        {creatingCustomerPortal && (
          <span
            style={{ marginRight: '1em', textAlign: 'center', height: '1em', width: '1em' }}
            className="spinner-border"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        Manage Subscription
      </button>
    </div>
  );
};
