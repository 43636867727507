import React from 'react';
import Navigation from '../../components/Nav/Nav';
import './termsAndPrivacy.css';
import LegalFooter from '../../components/legalFooter/LegalFooter';

const TermsAndPrivacy = () => {
  return (
    <div className="faq-container terms-container">
      <Navigation />
      <div className="faq-wrapper">
        <div className="faq-text-wrapper">
          <h2 className="text text--bold text--heading-1">Terms & Privacy</h2>
          <p className="text text--paragraph-3">
            By using this website (jumblejournal.org) or the application, you consent to our "Terms & Conditions" and
            "Privacy Policy", as well as those of the services we rely on. If you have any questions, you can reach us
            at support@jumblejournal.org
          </p>
          <h4 className="text text--heading-2">tldr;</h4>
          <p className="text text--paragraph-3">
            <ul>
              <li>
                We are not a big evil corporation and do not sell your data. We do not log your data. Your data is
                encrypted in transit and at rest.
              </li>
              <li>
                Our AI services are powered by OpenAI. As of March 1, 2023, OpenAI will not use API data to train or
                improve their models. This means that your content is completely private.
              </li>
              <li>
                We also track how users interact with our application. This tracking is anonymous and only used for
                tracking app and feature usage.
              </li>
              <li>We strive to collect the minimum amount of information required to operate our services.</li>
            </ul>
          </p>
          <h4 className="text text--heading-2">Privacy Policy Last updated September 1, 2023</h4>
          <h5 className="text text--heading-5 text--bold">1. Introduction</h5>
          <p className="text text--paragraph-3">
            <strong style={{ display: 'inline' }}>Jumble</strong>{' '}
            <div style={{ display: 'inline' }}>
              appreciates your trust. We are a EU-based company, creating products and services focused on mental health
              and wellbeing. Please read and consent to this Privacy Policy in order to have permission to use our
              services.
            </div>
          </p>
          <h5 className="text text--heading-5 text--bold">2.1 Data collected</h5>
          <p className="text text--paragraph-3">
            <strong style={{ display: 'inline' }}>Data storage location:</strong>{' '}
            <div style={{ display: 'inline' }}>
              We operate web servers hosted in Virginia, United States. Our hosting provider Amazon Web Services adheres
              to the EU/US Privacy Shield, ensuring that your data is securely stored and GDPR compliant.
            </div>
          </p>
          <p className="text text--paragraph-3">
            <strong style={{ display: 'inline' }}> Emails:</strong>{' '}
            <div style={{ display: 'inline' }}>
              When you register to our newsletter, your email address will be stored by our email service provider Email
              Octopus. You can find their privacy policy at{' '}
              <a href="https://emailoctopus.com/legal/privacy" className="link--no-effect">
                https://emailoctopus.com/legal/privacy
              </a>
              . You can unsubscribe at any time by clicking on the link at the bottom of each newsletter. When you sign
              up to Jumble, we store your email address securely on our web servers hosted by AWS.
            </div>
          </p>
          <p className="text text--paragraph-3">
            <strong style={{ display: 'inline' }}>Analytics:</strong>{' '}
            <div style={{ display: 'inline' }}>
              We use GoatCounter Analytics on our site for anonymous reporting of site usage. No personalised data is
              stored. You can find their privacy policy at{' '}
              <a href="https://www.goatcounter.com/help/privacy" className="link--no-effect">
                https://www.goatcounter.com/help/privacy
              </a>
            </div>
          </p>
          <p className="text text--paragraph-3">
            <strong style={{ display: 'inline' }}>Hosting platform:</strong>{' '}
            <div style={{ display: 'inline' }}>Our website and application are hosted on Amazon Web Services.</div>
          </p>
          <p className="text text--paragraph-3">
            <strong style={{ display: 'inline' }}>Sign in with Google:</strong>{' '}
            <div style={{ display: 'inline' }}>
              We give users the option to use their Google Account to sign-up and sign-in to out application. When you
              choose to use Sign in with Google, we will collect and use the following information from your Google
              account:
              <ul style={{ marginTop: '1em' }}>
                <li>
                  Your name and email address: This information is used to identify you and personalize your experience
                  within our application.
                </li>
                <li>
                  Your Google profile picture: This information is used to personalize your account within our
                  application.
                </li>
                <li>
                  Your Google user ID: This information is used to associate your Google Account with your account
                  within our application.
                </li>
              </ul>
            </div>
          </p>
          <p className="text text--paragraph-3">
            We use this information to provide you with access to our application and to personalize your experience
            within it. We also use this information to communicate with you about your account and to provide you with
            customer support. We will not share your Google Account information with any third-party services, except as
            required by law or to protect our rights or property.
          </p>
          <p className="text text--paragraph-3">
            <strong style={{ display: 'inline' }}>AI content generation:</strong>{' '}
            <div style={{ display: 'inline' }}>
              Our AI services are powered by OpenAI. With the new update on March 1, 2023, OpenAI will not use API data
              to train or improve their models. This means that your content is completely private. If you choose to use
              the Jumble AI feature(s), you may not use the Jumble AI features in a manner that violates any OpenAI
              Policy, including their Content Policy; Sharing and Publication Policy; and Community Guidelines.
            </div>
          </p>
          <p className="text text--paragraph-3">
            <strong style={{ display: 'inline' }}>Payments:</strong>{' '}
            <div style={{ display: 'inline' }}>
              We use Stripe as our payment processor. You can find their privacy policy at{' '}
              <a href="https://stripe.com/en-ee/legal/privacy-center" className="link--no-effect">
                https://stripe.com/en-ee/legal/privacy-center
              </a>
            </div>
          </p>
          <h5 className="text text--heading-5 text--bold">3. Use of personal data</h5>
          <p className="text text--paragraph-3">
            <strong>We use your personal information in the following cases:</strong>{' '}
            <p className="text text--paragraph-3">
              <ul style={{ marginTop: '1em' }}>
                <li>Verification/identification of the user during website usage.</li>
                <li>Providing technical assistance and customer support.</li>
                <li>Sending updates to our users with important information to inform about news/changes.</li>
                <li>
                  {' '}
                  Gather analysis about usage, so we can customise the website to make your experience more personal and
                  engaging.
                </li>
                <li> Guarantee overall performance and administrative functions run smoothly.</li>
              </ul>
            </p>
          </p>
          <h5 className="text text--heading-5 text--bold">4. Changes </h5>
          <p className="text text--paragraph-3">
            Changes to this privacy policy and terms of service: We may update this Privacy Policy from time to time to
            reflect changes in our practices or applicable law. If we make any material changes, we will notify you by
            email or by posting a notice on our website and application.
          </p>
        </div>
      </div>
      <LegalFooter />
    </div>
  );
};

export default TermsAndPrivacy;
