import React, { useState } from 'react';
import { File, Flag } from 'react-feather';
import { useGeneralContext } from '../../context';
import { updateUserData } from '../../api';
import { Colors } from './types';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Chip, Divider } from '@mui/material';
import { JumbleBotSwitch } from './AiAssistantToggle';
import PremiumUpsellModal from '../../components/PremiumUpsellModal';
import '../../css/App.css';
import './meatballMenu.css';

const ITEM_HEIGHT = 48;

const ColorSetting = (props: { setBgColor: React.Dispatch<React.SetStateAction<string>> }) => {
  const { setBgColor } = props;
  return (
    <ul className="color-picker settings__color-selector-container">
      <li className={`settings__color-container`}>
        <button
          onClick={() => {
            updateUserData({ entryBackgroundColor: Colors.White });
            setBgColor(Colors.White);
          }}
          style={{ backgroundColor: Colors.White }}
          className="settings__color"
        ></button>
      </li>
      <li className={`settings__color-container`}>
        <button
          onClick={() => {
            updateUserData({ entryBackgroundColor: Colors.Brown });
            setBgColor(Colors.Brown);
          }}
          style={{ backgroundColor: Colors.Brown }}
          className="settings__color"
        ></button>
      </li>
      <li className={`settings__color-container`}>
        <button
          onClick={() => {
            updateUserData({ entryBackgroundColor: Colors.Blue });
            setBgColor(Colors.Blue);
          }}
          style={{ backgroundColor: Colors.Blue }}
          className="settings__color"
        ></button>
      </li>
      <li className={`settings__color-container`}>
        <button
          onClick={() => {
            updateUserData({ entryBackgroundColor: Colors.Green });
            setBgColor(Colors.Green);
          }}
          style={{ backgroundColor: Colors.Green }}
          className="settings__color"
        ></button>
      </li>
      <li className={`settings__color-container`}>
        <button
          onClick={() => {
            updateUserData({ entryBackgroundColor: Colors.Dark });
            setBgColor(Colors.Dark);
          }}
          style={{ backgroundColor: Colors.Dark }}
          className="settings__color"
        ></button>
      </li>
    </ul>
  );
};

const MeatballMenu = (props: {
  journalAssistant: boolean;
  setJournalAssistant: React.Dispatch<React.SetStateAction<boolean>>;
  setEntryBackgroundColor: React.Dispatch<React.SetStateAction<string>>;
  setBlur: React.Dispatch<React.SetStateAction<boolean>>;
  onUpgradeClick: () => void;
}) => {
  const context = useGeneralContext();
  const { setFeedbackModalOpen, subscriptionStatus } = context;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showModule, setShowModule] = useState(false);
  const open = Boolean(anchorEl);

  const { journalAssistant, setJournalAssistant, setEntryBackgroundColor, setBlur, onUpgradeClick } = props;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setBlur(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setBlur(false);
    setAnchorEl(null);
  };

  const toggleJumbleBot = () => {
    setBlur(false);
    if (journalAssistant) {
      return setJournalAssistant(false);
    }

    if (!subscriptionStatus || subscriptionStatus === 'free') {
      setShowModule(true);
      return;
    }
    setJournalAssistant(true);
    window.alert('Journaling Assistant is ready! Press enter while journaling to start the conversation.');
  };

  return (
    <div className="meatball-menu upper-right-fixed">
      <PremiumUpsellModal
        showModal={showModule}
        setShowModal={setShowModule}
        message="Get access to the Jumble Assistant and get more out of your journaling experience"
        onUpgradeClick={onUpgradeClick}
      />
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon className="back-button__icon" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        style={{ opacity: '.8' }}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch'
            }
          }
        }}
      >
        <div className="single-entry__options-item">
          <JumbleBotSwitch
            onClick={() => {
              toggleJumbleBot();
            }}
            journalAssistant={journalAssistant}
          />
        </div>
        <Divider />
        <MenuItem
          key="report"
          selected={false}
          onClick={() => {
            setFeedbackModalOpen(true);
            handleClose();
          }}
        >
          <div className="report-issue-wrapper flex-row-space-between">
            <div className="text text--regular-weight text--body-medium text--neutral-800">Report</div>
            <Flag size={18} />
          </div>
        </MenuItem>
        <MenuItem
          key="feedback"
          selected={false}
          onClick={() => {
            setFeedbackModalOpen(true);
            handleClose();
          }}
        >
          <div className="report-issue-wrapper flex-row-space-between">
            <div className="text text--regular-weight text--body-medium text--neutral-800">Feedback</div>
            <File size={18} />
          </div>
        </MenuItem>
        <Divider>
          <Chip label="Choose a Color" />
        </Divider>
        <ColorSetting setBgColor={setEntryBackgroundColor} />
      </Menu>
    </div>
  );
};

export default MeatballMenu;
