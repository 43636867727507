import { Link } from 'react-router-dom';

export const faqData = [
  {
    question: 'How do I save my journal entry?',
    answer:
      'Journal entries are saved automatically every 20 seconds and when you navigate away from the page. You can also use the CMD+S or CTRL+S hot keys to save at any time.'
  },
  {
    question: 'How much should I write for a single journal entry?',
    answer:
      "In general, 500 words is a good target. It's long enough for you to get into some deeper thinking, and short enough that it's not a major inconvenience to complete daily. However, consistency is the most important thing — don't hesitate to write a single sentence if you don't have the energy to write more on a given day."
  },
  {
    question: 'How often should I journal?',
    answer:
      "Every day. Like meditation, meaningful progress depends on regularity. Don't worry if you miss a day. Start again. If you don't have energy, just write a single sentence to keep the habit going. Try to journal at the same time everyday in order to get into a rhythm that can be maintained."
  },
  {
    question: 'What should I write about when journaling?',
    answer:
      'Anything that comes to mind. If you listen, something will pop into your mind. Try not to filter and just write what comes to mind.Alternatively, you can focus on a particular subject or topic that is top of mind. Journaling can be used as a tool to solve problems. If you need some ideas, try asking the Jumble AI for some inspiration.'
  },
  {
    question: 'What are the tags used for? What tags should I create?',
    answer:
      'Tags are there to help you explore past entries. A general rule of thumb is to create one tag per paragraph that you write. For example, if you are writing about your sisters upcoming birthday, you might tag the entry with ‘family’. You can create as many tags as you need. Re-using tags across entries is good, as you can later explore the various entries pertaining to a specific tag.'
  },
  {
    question: 'What are the available features?',
    answer:
      'Currently, Jumble supports daily journaling, tagging, Jumble AI for journaling inspiration, and habit building metrics and insights. We also have a daily email notification feature. Jumble is designed to get out of the way and let you think and write.'
  },
  {
    question: 'How much does Jumble cost?',
    answer:
      'Jumble is free. Secure daily journaling will always be free. In the future we hope to introduce some premium features on top of the free tier. We could not continue to build and improve this product were it not for the generous donations from our users (donation link at the bottom of page).'
  },
  {
    question: 'Is Jumble GDPR compliant?',
    answer:
      'We adhere to the primary tenets. If you desire to export or delete your data, reach out to support@jumblejournal.org. Personally identifiable information such as email are anonymised whenever possible using a unique user UUID.'
  },
  {
    question: 'Who created Jumble?',
    answer: (
      <>
        {`A few friends collaborating in Portugal. `}
        <Link to="/about">Check out our story!</Link>
      </>
    )
  },
  {
    question: 'Why did you build Jumble?',
    answer: (
      <>
        {`Mental health is of paramount importance to us. Check out our story `}
        <Link to="/about">here!</Link>
      </>
    )
  }
];
