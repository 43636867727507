export const Colors = {
  Dark: '#3a3a3a',
  Blue: '#c5d5e8',
  Green: '#c5e8d5',
  White: '#ffffff',
  Brown: '#d5c5b5'
} as const;

export type Color = (typeof Colors)[keyof typeof Colors];

export const DEFAULT_ASSISTANT = 'Jumble Therapist';
