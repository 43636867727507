import Form from 'react-bootstrap/Form';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const JumbleBotSwitch = (props: { journalAssistant: boolean; onClick: () => void }) => {
  const { onClick, journalAssistant } = props;

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="word-count-tooltip">
            Click here to turn on Jumble Bot. Then, press 'Enter' while journaling to interact with Jumble Bot.
          </Tooltip>
        }
      >
        <>
          <div className="text text--regular-weight text--body-medium text--neutral-800">AI Assistant</div>
          <Form.Check
            onMouseEnter={(e) => ((e.target as any).style.cursor = 'pointer')} // Change cursor to pointer on hover
            onMouseLeave={(e) => ((e.target as any).style.cursor = 'default')} // Revert cursor to default when leaving
            type="switch"
            id="custom-switch"
            label=""
            checked={journalAssistant}
            onChange={onClick}
          />
        </>
      </OverlayTrigger>
    </>
  );
};
