import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/App.css';

const LegalFooter = () => {
  return (
    <div className="footer">
      <div className="landingpage__footer-bottom">
        <div className="text text--caption landingpage__footer-bottom-left">Jumble © 2023. All rights reserved.</div>
        <div className="landingpage__footer-bottom-right">
          <Link to="/terms-and-privacy" aria-current="page" className="text text--caption link--no-effect">
            Terms &amp; Privacy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LegalFooter;
