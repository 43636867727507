import React, { useContext } from 'react';
import { AuthEventData, AmplifyUser } from '@aws-amplify/ui';

type AuthContextType = {
  signOut: ((data?: AuthEventData | undefined) => void) | undefined;
  user: AmplifyUser | undefined;
};

const AuthContext = React.createContext<AuthContextType | null>(null);

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext was used outside of its Provider');
  }
  const { user, signOut } = context;
  if (!user || !signOut) {
    throw new Error('useAuthContext user or signOut is undefined');
  }

  return { user, signOut };
};

export { AuthContext, useAuthContext };
