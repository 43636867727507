import React from 'react';
import { retrieveJournalEntry } from '../../api';
import { EntryFromS3, isEntryFromS3 } from './types';

export const loadExistingEntry = async (params: {
  entryName: string;
  setOpeningEntry?: React.Dispatch<React.SetStateAction<boolean>>;
}): Promise<{ tags: string[] } & EntryFromS3> => {
  const { entryName, setOpeningEntry } = params;
  try {
    setOpeningEntry && setOpeningEntry(true);
    const key = entryName;
    const response = await retrieveJournalEntry({ entryDateTitle: key });
    const { body, tags } = response.data;
    const formattedEntry = formatEntry({ entry: body });
    setOpeningEntry && setOpeningEntry(false);
    return {
      tags: tags || [],
      ...formattedEntry
    };
  } catch (error) {
    setOpeningEntry && setOpeningEntry(false);
    throw error;
  }
};

const formatEntry = (params: { entry: string }): EntryFromS3 => {
  const { entry } = params;

  try {
    const entryState = JSON.parse(entry);
    if (!isEntryFromS3(entryState)) {
      throw new Error();
    }
    return entryState;
  } catch (_error) {
    // try treating the entry as a basic string then
    return {
      blocks: [{ role: 'user', content: entry || '' }],
      imported: false
    };
  }
};
