import React, { useContext, useReducer, useCallback } from 'react';
import EntryContext from './entries-context';
import entriesReducer, { EntryState } from './entries-reducer';

import { EntryActions } from './entries-actions';

const EntryStateProvider = (props: { children: any }) => {
  const initialState: EntryState = { entries: [] };

  const [state, dispatch] = useReducer(entriesReducer, initialState);

  // Add entries
  const addEntry = (name: string, imported: boolean) => {
    dispatch({
      type: EntryActions.ADD_ENTRY,
      payload: { name, imported }
    });
  };

  // Add entries
  const setEntries = useCallback((entries: { name: string; imported: boolean }[]) => {
    dispatch({
      type: EntryActions.SET_ENTRIES,
      payload: { entries }
    });
  }, []);

  // Delete a entries
  const deleteEntry = (name: string) => {
    dispatch({
      type: EntryActions.DELETE_ENTRY,
      payload: { name }
    });
  };

  return (
    <EntryContext.Provider
      value={{
        entries: state.entries,
        addEntry,
        setEntries,
        deleteEntry
      }}
    >
      {props.children}
    </EntryContext.Provider>
  );
};

// context consumer hook
const useEntryContext = () => {
  // get the context
  const context = useContext(EntryContext);

  if (!context) {
    throw new Error('Entry context was used outside of its Provider');
  }

  return context;
};

export { EntryStateProvider, useEntryContext };
