import React, { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Activity, HelpCircle, Home, Settings, Map } from 'react-feather';
import { useMediaQuery } from 'react-responsive';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { useGeneralContext, useAuthContext } from '../../context';
import '../../css/App.css';
import logo from '../../resources/dark-logo.png';
import Logo from '../Logo';
import LogoutButton from '../Logout';
import PremiumUpsellModal from '../PremiumUpsellModal';
import { getMenuItems } from './data';
import './nav.css';

type NavType = 'sideNav' | 'topNav' | 'topNavPrivate' | 'topNavPublic';
type NavRule = { largeScreen: NavType; smallScreen: NavType; customMaxSmallScreenSize?: string };

const APP_NAV: NavRule = {
  largeScreen: 'sideNav',
  smallScreen: 'topNavPrivate'
};

const PUBLIC_NAV: NavRule = {
  largeScreen: 'topNavPublic',
  smallScreen: 'topNav'
};

const navRules: { [path: string]: NavRule } = {
  '/': PUBLIC_NAV,
  '/about': PUBLIC_NAV,
  '/faq': PUBLIC_NAV,
  '/terms-and-privacy': PUBLIC_NAV,
  '/home': { ...APP_NAV, customMaxSmallScreenSize: '1430px' },
  '/chat': APP_NAV,
  '/settings': APP_NAV,
  '/retrospective': APP_NAV
};

const appPaths = ['/home', '/chat', '/settings', '/retrospective'];

const Navigation = () => {
  const { setFeedbackModalOpen, setPlansModalOpen } = useGeneralContext();
  const history = useHistory();

  const path = window.location.pathname;
  const loggedIn = appPaths.includes(path);

  const navRule = navRules[path];
  const maxSmallScreenSize = navRule.customMaxSmallScreenSize || '1250px';
  const screenSize = useMediaQuery({ query: `(max-width: ${maxSmallScreenSize})` }) ? 'smallScreen' : 'largeScreen';
  const navComponentToUse = navRules[path][screenSize];

  const menuItems = getMenuItems({ setFeedbackModalOpen, setPlansModalOpen, history, loggedIn: loggedIn });

  if (navComponentToUse === 'sideNav') {
    return <SideNav />;
  }
  if (navComponentToUse === 'topNav') {
    return <TopNav loggedIn={loggedIn} menuItems={menuItems} />;
  }
  if (navComponentToUse === 'topNavPrivate') {
    return <TopNavPrivate menuItems={menuItems} />;
  }
  // topNavPublic
  return <LandingPageLargeScreenTopNav menuItems={menuItems} />;
};

const SideNav = () => {
  const context = useGeneralContext();
  const location = useLocation();
  const { nickname, feedbackModalOpen, setFeedbackModalOpen, subscriptionStatus } = context;
  const history = useHistory();

  return (
    <div className="side-nav">
      <div className="side-nav__top">
        <Logo style={{ width: '100px' }} includeTitle={true} />
        <div className="side-nav__top-section2">
          <Link
            to="/home"
            className={`side-nav__item ${location.pathname.includes('/home') ? 'side-nav__item--selected' : ''}`}
          >
            <Home className="side-nav__item-logo text--primary-500" />
            <div className="text text--regular-weight text--paragraph-1">Home</div>
          </Link>
          <Link
            to="#"
            className={`side-nav__item ${location.pathname.includes('/chat') ? 'side-nav__item--selected' : ''}`}
            onClick={() => {
              history.push('/chat');
            }}
          >
            <Activity className="side-nav__item-logo text--primary-500" />
            <div className="side-nav__item-text text text--regular-weight text--paragraph-1">
              Chat with your Journal
            </div>
          </Link>
          <Link
            to="#"
            className={`side-nav__item ${
              location.pathname.includes('/retrospective') ? 'side-nav__item--selected' : ''
            }`}
            onClick={() => {
              history.push('/retrospective');
            }}
          >
            <Map className="side-nav__item-logo text--primary-500" />
            <div className="text text--regular-weight text--paragraph-1">Explore your Journal</div>
          </Link>
        </div>
      </div>
      <div className="side-nav__bottom">
        <div className="side-nav__bottom-section1">
          <button
            onClick={() => {
              setFeedbackModalOpen(true);
            }}
            className={`side-nav__item ${feedbackModalOpen ? 'side-nav__item--selected' : ''}`}
          >
            <HelpCircle className={`side-nav__item-logo text--primary-800`} />
            <div className="text text--regular-weight text--paragraph-1 text--primary-800">Chat with us</div>
          </button>
          <Link
            to="/settings"
            className={`side-nav__item ${location.pathname.includes('/settings') ? 'side-nav__item--selected' : ''}`}
          >
            <Settings className="side-nav__item-logo text--primary-800" />
            <div className="text text--regular-weight text--paragraph-1 text--primary-800">Settings</div>
          </Link>
        </div>
        <div className="side-nav__bottom-section2">
          <div className="side-nav__name-and-plan">
            <div className="side-nav__name text text--paragraph-1 text--bold">{nickname}</div>
            <div className="side-nav__plan text text--caption">{convertUserSubToString(subscriptionStatus)}</div>
          </div>
          <LogoutButton label="" />
        </div>
      </div>
    </div>
  );
};

const convertUserSubToString = (userSub: string | null) => {
  const UserSubToStringMap = {
    lifetime: 'Lifetime Plan',
    premium_monthly: 'Premium plan',
    premium_yearly: 'Premium plan',
    free_trial: 'Free trial',
    free: 'Free plan'
  };
  type UserSub = keyof typeof UserSubToStringMap;

  const isUserSub = (userSub: string): userSub is UserSub => {
    return Object.keys(UserSubToStringMap).includes(userSub);
  };

  return typeof userSub !== 'string' || !isUserSub(userSub) ? 'Free plan' : UserSubToStringMap[userSub];
};

type menuItem = {
  text: string;
  destination?: string;
  onClick?: () => void;
  icon?: any;
};

const TopNavPrivate = (props: { menuItems: menuItem[] }) => {
  const { signOut } = useAuthContext();
  return <TopNav loggedIn={true} menuItems={props.menuItems} signOut={signOut} />;
};

const TopNav = (props: { loggedIn: boolean; menuItems: menuItem[]; signOut?: any }) => {
  const history = useHistory();
  const [navOpen, setNavOpen] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1250px)' });

  const { loggedIn, menuItems, signOut } = props;

  const handleToggle = () => {
    setNavOpen(!navOpen);
  };

  const handleClose = () => {
    setNavOpen(false);
  };

  return (
    <Navbar collapseOnSelect fixed="top" expand="xxl" bg="light" variant="light" className="mb-3">
      <Container>
        <Navbar.Toggle onClick={handleToggle} />
        <Navbar.Collapse in={navOpen}>
          <Nav className="mr-auto">
            <NavLink
              to={loggedIn ? '/home' : '/'}
              style={{ display: 'flex', justifyContent: 'center' }}
              onClick={() => {
                handleClose();
              }}
            >
              <img
                style={
                  isTabletOrMobile
                    ? { width: '96px', alignSelf: 'center' }
                    : { width: '96px', alignSelf: 'center', margin: '4px 16px 0 0' }
                }
                alt="logo"
                src={logo}
              ></img>
            </NavLink>
            {menuItems.map((item, index) => {
              const dest = item.destination;
              return dest ? (
                <NavLink
                  isActive={() => history?.location?.pathname === item.destination}
                  style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                  key={`nav-item-${index}`}
                  className={'topnav__link'}
                  to={item.destination}
                  onClick={() => {
                    handleClose();
                    if (signOut && item.text === 'Log out') {
                      sessionStorage.removeItem('stripeCustomerId');
                      signOut();
                    }
                    item.onClick && item.onClick();
                  }}
                >
                  {item.icon}
                  {item.text}
                </NavLink>
              ) : (
                <button
                  onClick={() => {
                    item.onClick && item.onClick();
                    handleClose();
                  }}
                  style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                  className="topnav__link"
                  key={`nav-item-${index}`}
                >
                  {item.icon}
                  {item.text}
                </button>
              );
            })}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const LandingPageLargeScreenTopNav = (props: { menuItems: menuItem[] }) => {
  const { menuItems } = props;
  const [showExploreBlockedModal, setShowExploreBlockedModal] = React.useState(false);
  const history = useHistory();
  const [navOpen, setNavOpen] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1250px)' });

  const handleToggle = () => {
    setNavOpen(!navOpen);
  };

  const handleClose = () => {
    setNavOpen(false);
  };

  const leftGroup = menuItems.slice(0, 4);
  const rightGroup = menuItems.slice(4, menuItems.length);

  return (
    <Navbar collapseOnSelect fixed="top" expand="lg" bg="light" variant="light" className="mb-3">
      <PremiumUpsellModal
        showModal={showExploreBlockedModal}
        setShowModal={setShowExploreBlockedModal}
        message="Get access to journal exploration and chat with your past journal entries."
      />
      <Container>
        <Navbar.Toggle onClick={handleToggle} />
        <Navbar.Collapse in={navOpen}>
          <div className="flex-row-centered flex-gap-40" style={{ width: '100%' }}>
            <Nav className="mr-auto">
              <NavLink
                to="/"
                style={{ display: 'flex', justifyContent: 'center' }}
                onClick={() => {
                  handleClose();
                }}
              >
                <img
                  style={
                    isTabletOrMobile
                      ? { width: '128px', alignSelf: 'center' }
                      : { width: '128px', alignSelf: 'center', margin: '4px 16px 0 0' }
                  }
                  alt="logo"
                  src={logo}
                ></img>
              </NavLink>
            </Nav>
            <div className="flex-row-space-between" style={{ width: '100%' }}>
              <div className="flex-row-centered flex-gap-8">
                {leftGroup.map((item, index) => {
                  return item.destination ? (
                    <NavLink
                      isActive={() => history?.location?.pathname === item.destination}
                      style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                      key={`nav-item-${index}`}
                      className="topnav__link"
                      to={item.destination}
                      onClick={() => {
                        handleClose();
                        item.onClick && item.onClick();
                      }}
                    >
                      {item.icon}
                      <div className="text text--paragraph-2">{item.text}</div>
                    </NavLink>
                  ) : (
                    <button
                      onClick={() => {
                        item.onClick && item.onClick();
                        handleClose();
                      }}
                      style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                      className="topnav__link"
                      key={`nav-item-${index}`}
                    >
                      {item.icon}
                      <div className="text text--paragraph-2">{item.text}</div>
                    </button>
                  );
                })}
              </div>
              <div className="flex-row-centered flex-gap-8">
                {rightGroup.map((item, index) => {
                  return (
                    <NavLink
                      isActive={() => history?.location?.pathname === item.destination}
                      style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                      key={`nav-item-${index}`}
                      className={`${
                        index === 0
                          ? 'jumble-button-secondary side-nav__item--login text--primary-700'
                          : 'jumble-button-primary side-nav__item--create-account text--white'
                      }  text text--paragraph-2 text--semi-bold`}
                      to={item.destination}
                      onClick={() => {
                        handleClose();
                        item.onClick && item.onClick();
                      }}
                    >
                      {index === 1 ? null : item.icon}
                      {item.text}
                    </NavLink>
                  );
                })}
              </div>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

// export default TopNav;
export default Navigation;
