import philosopherSnail from './resources/absurd.design_1.27_philosopher-snail.png';

export const PageNotFound = () => {
  return (
    <div style={{ gridColumn: '2/11' }}>
      <h2
        style={{
          backgroundColor: '#f3f3f3',
          fontFamily: 'Arial, sans-serif',
          textAlign: 'center',
          padding: '50px'
        }}
      >
        Looks like this page is taking the slow route. It's Not Found.
      </h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img alt="a snail thinking" style={{ maxHeight: '20em' }} src={philosopherSnail}></img>
      </div>
    </div>
  );
};
