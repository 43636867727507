export const premiumFeatures = [
  {
    name: 'Journal Assistant',
    description:
      'Leverage our AI-powered assistant to help you with your journal entries. Get inspiration, recommendations and much more.',
    icon: '🤖'
  },
  {
    name: '(✨New!) Offline Journaling',
    description:
      "Journal the old fashion way with pen and paper. Then send a picture of the entry to your private jumble email address. We'll do the rest!",
    icon: '✍🏼'
  },
  {
    name: 'Personal Prompts',
    description:
      'Customize your journal with personal prompts. Get those creative juices flowing and make every entry unique.',
    icon: '📝'
  },
  {
    name: 'Journal Time Machine',
    description:
      'Travel back in time and indulge in the nastalgia. Jumble surfaces your nuggets of wisdom, see patterns and reflect on your personal growth.',
    icon: '⏰⚡'
  },
  {
    name: 'Habit dashboard',
    description: 'Monitor your journaling habit and track your progress. Set goals, streaks and celebrate milestones.',
    icon: '🔥'
  },
  {
    name: '(✨New!) Talk with your Journal',
    description: 'A private Chat GPT with your entire journal embedded. Have a conversation with your past self.',
    icon: '🪞'
  },
  {
    name: 'Same day support',
    description: 'Get priority support when you need it. Our team is always ready to assist you on the same day.',
    icon: '☎️'
  },
  {
    name: 'And more to come!',
    description:
      'We continuously improve and add new features. As a premium user, you get access to all new features at no additional cost.',
    icon: '🚧'
  }
];

export const COUNTRY_CURRENCY_MAP: { [key: string]: { code: SupportedCurrencyCode; symbol: string } } = {
  US: { code: 'usd', symbol: '$' },

  // Eurozone countries
  AT: { code: 'eur', symbol: '€' }, // Austria
  BE: { code: 'eur', symbol: '€' }, // Belgium
  CY: { code: 'eur', symbol: '€' }, // Cyprus
  EE: { code: 'eur', symbol: '€' }, // Estonia
  FI: { code: 'eur', symbol: '€' }, // Finland
  FR: { code: 'eur', symbol: '€' }, // France
  DE: { code: 'eur', symbol: '€' }, // Germany
  GR: { code: 'eur', symbol: '€' }, // Greece
  IE: { code: 'eur', symbol: '€' }, // Ireland
  IT: { code: 'eur', symbol: '€' }, // Italy
  LV: { code: 'eur', symbol: '€' }, // Latvia
  LT: { code: 'eur', symbol: '€' }, // Lithuania
  LU: { code: 'eur', symbol: '€' }, // Luxembourg
  MT: { code: 'eur', symbol: '€' }, // Malta
  NL: { code: 'eur', symbol: '€' }, // Netherlands
  PT: { code: 'eur', symbol: '€' }, // Portugal
  SK: { code: 'eur', symbol: '€' }, // Slovakia
  SI: { code: 'eur', symbol: '€' }, // Slovenia
  ES: { code: 'eur', symbol: '€' }, // Spain

  // Other countries
  GB: { code: 'gbp', symbol: '£' }, // United Kingdom
  AU: { code: 'aud', symbol: '$' } // Australia
};

export type SupportedCountry =
  | 'AT'
  | 'BE'
  | 'CY'
  | 'EE'
  | 'FI'
  | 'FR'
  | 'DE'
  | 'GR'
  | 'IE'
  | 'IT'
  | 'LV'
  | 'LT'
  | 'LU'
  | 'MT'
  | 'NL'
  | 'PT'
  | 'SK'
  | 'SI'
  | 'ES'
  | 'GB'
  | 'AU'
  | 'US';

export const isSupportedCountry = (country: string): country is SupportedCountry => {
  return country in COUNTRY_CURRENCY_MAP;
};

export type SupportedCurrencyCode = 'eur' | 'usd' | 'aud' | 'gbp';

export const isSupportedCurrencyCode = (currencyCode: string): currencyCode is SupportedCurrencyCode => {
  return ['eur', 'usd', 'aud', 'gbp'].includes(currencyCode);
};
